.AirlineCredentialFlow {
  width: 100%;
  height: 100vh;
  .Container {
    display: flex;
    height: 100%;
    background-color: var(--base-color-gray);
    .LeftContainer {
      width: 50%;
      height: 100%;
      padding: 88px 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      box-sizing: border-box;
      .TopLogo {
        img {
          width: 120px;
        }
        .MiniDivder {
          width: 72px;
          border-bottom: 2px solid var(--base-color-primary);
          margin-top: 6px;
          margin-left: 2px;
        }
      }

      .CarouselContainer {
        margin-top: 24px;

        .MetaInfo {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          margin-bottom: 64px;
        }
        .CarouselCard {
          padding: 40px 48px;
          background-color: white;
          border-radius: 12px;
          position: relative;
          height: 198px;
          box-sizing: border-box;
          .quoteImage {
            position: absolute;
            top: 0;
          }
          .UserInfo {
            margin-top: 28px;
            display: flex;
            align-items: center;
            img {
              filter: grayscale(100%);
            }
            .UserDetails {
              margin-left: 12px;
            }
          }
        }
      }
      .CertificateContainer {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
    .RightContainer {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: right;
      justify-content: center;
      padding: 0 5%;
      box-sizing: border-box;
      flex-direction: column;
      .TopLogo {
        img {
          width: 120px;
        }
      }
      .AirlineStart {
        padding: 20px 24px;

        box-sizing: border-box;
        width: 100%;
        min-height: 50%;
        background-color: white;
        border-radius: 12px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        .QuestionareContainer {
          display: flex;
          flex-direction: column;
          .QuestionBox {
            border-radius: 8px;
            width: 100%;
            border: 1px solid #dfe0e7;
            padding: 20px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;
            cursor: pointer;
            .LeftQuestion {
              display: flex;
              align-items: center;
              .IconBox {
                height: 44px;
                width: 44px;
                border-radius: 50%;
                background: #0a83941f;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 24px;
              }
            }
          }
          .--selected {
            border: 1px solid #00848b33;
            background: #e5fbfd80;
          }
        }
        .FormContainer {
          margin-top: 12px;
          .FormGroup {
            margin-bottom: 12px;
          }
        }

        .EnitiyInfoContainer {
          margin-top: 12px;
          .EntityNameContainer {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-bottom: 12px;
          }
        }
        .EntityBoxContainer {
          display: flex;
          flex-wrap: wrap;
          margin-top: 12px;

          .EntityBox {
            border: 1px solid #dfe0e7;
            padding: 12px;
            box-sizing: border-box;
            border-radius: 8px;
            margin-right: 8px;
            cursor: pointer;
            .EntityBoxFooter {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 18px;
            }
          }
        }
        .FormContainer {
          display: flex;
          flex-direction: column;
          margin-top: 24px;
          .fieldGroup {
            display: flex;
            flex-direction: column;
            margin-bottom: 12px;
          }
        }
      }
    }
  }

  //ant-design modifiers
  .ant-input-outlined {
    // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.3rem`,
    border-width: 0.13rem !important;
    box-shadow: none;
    &:hover {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.3rem`,
      // border-width: 3px;
      box-shadow: rgba(50, 104, 185, 0.25) 0px 0px 0px 0.25rem !important;
    }
    &:focus {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.3rem`,
      // border-width: 3px !important;
      box-shadow: rgba(50, 104, 185, 0.25) 0px 0px 0px 0.25rem !important;
    }
  }
}

.carousel .carousel-slider {
  .control-dots {
    .dot {
      background: red !important;
    }
  }
}
