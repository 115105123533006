.existinguser-container{
    height: 100vh;
    padding: 50px;
}
.existinguser-navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
}
.existinguser-left-nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.existinguser-btns-div{
    display: flex;
    align-items: center;
    gap: 20px;
}
.knowmore-btn-1 {
    display: flex;
    padding: 10px 60px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: transparent;
    border: 0.75px solid var(--Neutral-500, #C3C4CD);
    font-size: 16px; /* Adjust font size as needed */
    font-weight: 500; /* Optional for styling text weight */
    line-height: 1; /* Ensure consistent line height */
    white-space: nowrap; /* Prevent text from wrapping */
    cursor: pointer; /* Change cursor to pointer for better UX */
    transition: background-color 0.3s ease, color 0.3s ease; /* Optional for hover effects */
}
.getstarted-btn-1{
    display: flex;
justify-content: center;
align-items: center;
border-radius: 4px;
padding: 10px 60px;
background: var(--Primaty2-Blue-700, #0A8394);
color: white;
border: none;
}
.existinguser-header{
    border-radius: 8px;
border: 1px solid #03A5B7;
background: rgba(10, 131, 148, 0.12);
display: flex;
padding: 10px 20px;
justify-content: space-between;
align-items: center;
margin-top: 20px;
}
.img-container{
    width: 30px;
    height: 30px;
}
.tick-img{
    height: 100%;
    width: 100%;
}
.headerleftpart{
    display: flex;
    gap: 10px;
}
.existingusers-content{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 40px;
    justify-content: space-between;}
.section-right{
    width: 40%;
    padding: 20px;
    display: flex;
flex-direction: column;
align-items: flex-start;
gap: 32px;
}
.section-left{
    margin-right: 110px;
}
.getstarted-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 10px 40px;
    background: var(--Primaty2-Blue-700, #0A8394);
    color: white;
    border: none;
    width: 100%;
}

.integration-badge {
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 12%;
    border-radius: 72px;
    color: #0A8394;
    border: 1px solid;
    font-family: "Noto Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .maintext{
    color: var(--Neutral-950, #212121);

/* Regular Head/Regular H5 */
font-family: "Noto Sans";
font-size: 36px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .des{
    color: var(--Neutral-800, #3F4047);
font-family: "Noto Sans";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }
  .clients-info{
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .clients-logos{
    display: flex;
    align-items: center;
    gap: 90px;
  }
  .existinguser-footer{
    width: 100%;
    border-radius: 8px;
border: 1px solid #03A5B7;
background: rgba(10, 131, 148, 0.12);
display: flex;
padding: 12px 16px;
align-items: center;
gap: 40px;
margin-top: 40px;
  }
  .footer-cards{
    width: 30%;
    gap: 10px;
    display: flex;
  }
  .footer-des{
    color: var(--Neutral-700, #72747D);
/* Base Text/Regular M */
font-family: "Noto Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }

  