/* BlankCard.css */

/* Default styles (for medium to large screens) */
.blank-card {
  background-color: #fff; /* White background */
  border-radius: 16px;     /* Rounded corners */
  box-shadow: 0px 2px 12px 0px rgba(13, 10, 44, 0.15);
  display: flex;          /* Flexbox for alignment */
  height: 338px;
  padding: 20px;
  width: 95%
}


