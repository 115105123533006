.OpenReportList {
  display: flex;
  height: 100%;
  .PrimaryNavContainer {
    width: 80px;
  }
  .MainSectionContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    .HeaderContainer {
      height: 40px;
    }
    .MainContainer {
      flex: 1;
      .ViewContainer {
        height: 100%;
        width: 100%;
      }
    }
  }
}
