.vendorHeader {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
    background-color: #f9f9f9; // Light gray background
    padding: 10px 120px 10px 45px; // Adjust padding as needed
    font-family: 'Arial', sans-serif;
  
    .logo {
      display: flex;
      align-items: center;
  
      .logo-text-main {
        font-size: 20px;
        font-weight: bold;
        color: #0099cc; // Blue color for "fin"
      }
  
      .logo-text-sub {
        font-size: 20px;
        color: #4a4a4a; // Gray color for "kraft.ai"
        margin-left: 2px;
      }
    }
  
    .help {
      font-size: 14px;
      color: #4a4a4a; // Gray text color
  
      a {
        color: #0099cc; // Blue link color
        text-decoration: none;
  
        &:hover {
          text-decoration: underline; // Underline on hover for the link
        }
      }
    }
  }
  