.CreateWorkspace {
  margin-top: 24px;

  .workspaceBasicInfo {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: flex-end;
  }
  .BottomAction {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
