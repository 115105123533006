.SingleInvoiceUpload {
  width: 100wh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  .TopInformationContainer {
    height: 72px;
    background-color: #ecf0f1;
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: center !important;
  }
  .LogoContainer {
    display: flex;
    align-items: center;
    justify-content: center !important;
    // justify-content: space-between;

    margin-bottom: 24px;
    width: 100%;
    padding: 0 100px;
    box-sizing: border-box;
    width: 760px;
    img {
      width: 150px;
    }
  }

  .UploadContainer {
    border-radius: 4px;
    width: 400px;
    height: 320px !important;
    padding: 24px;
    box-sizing: border-box;
    background-color: transparent !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
