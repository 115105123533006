.styled-table {
    width: 100%;
    border-collapse: collapse;
    // margin: 8px 0;
    margin-bottom: 8px;
    margin-top: 0.5px;
    font-size: 14px;
    border: 1px solid #EDEFF5;
    border-radius: 5px !important;
    table-layout: fixed;
}

.styled-table td {
    border: 1px solid #EDEFF5;
    border-radius: 5px;
    color: #72747D;
    padding: 8px 16px;
    font-size: 12px;

}

.styled-table td:nth-child(3) {
    text-align: center;
    vertical-align: middle;
}

.styled-table th {
    background-color: #F8F9FB;
    font-size: 14px;
    color: #72747D;
    font-family: "Noto Sans";
    font-weight: 400;
    border: 1px solid #EDEFF5;
    padding: 12px 16px;
    text-align: left;
    position: sticky;
    top: 0;
    z-index: 1; 
    // display: flex;
    // justify-content:flex-start;
    // align-items: flex-start;
}

.styled-table tr:hover {
    background-color: #f9f9f9;
}


.FormContainer1 {
     max-height: 300px;  
     overflow-y: auto;
}

// .styled-table {
//     width: 100%;
//     border-collapse: collapse;
//     margin-bottom: 8px;
//     margin-top: 0.5px;
//     font-size: 14px;
//     border: 1px solid #EDEFF5;
//     border-radius: 5px !important;
//     table-layout: fixed;
//     overflow: hidden;
// }

// .styled-table td {
//     border: 1px solid #EDEFF5;
//     border-radius: 5px;
//     color: #72747D;
//     padding: 8px 16px;
//     font-size: 12px;
// }

// .styled-table td:nth-child(3) {
//     text-align: center;
//     vertical-align: middle;
// }

// .styled-table th {
//     background-color: #F8F9FB;
//     font-size: 14px;
//     color: #72747D;
//     font-family: "Noto Sans";
//     font-weight: 400;
//     border: 1px solid #EDEFF5;
//     padding: 12px 16px;
//     text-align: left;
//     position: sticky;
//     top: 0;
//     z-index: 1;
// }

// .styled-table tr:hover {
//     background-color: #f9f9f9;
// }

// /* Make the body part scrollable */
// .styled-table-wrapper {
//     max-height: 300px;
//     overflow-y: auto;
// }

// .styled-table tbody {
//     display: block;
//     max-height: 250px;
//     overflow-y: auto;
// }

// .styled-table thead,
// .styled-table tbody tr {
//     display: table;
//     width: 100%;
//     table-layout: fixed;
// }

// .FormContainer1 {
//     max-height: 300px;
//     overflow-y: auto;
// }
