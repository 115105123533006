/* Modal Overlay */
.uploadcontact-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  /* Modal Container */
  .uploadcontact-modal-container {
    background: #fff;
    border-radius: 8px;
    padding: 30px;
    width: 450px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;

    .download-data-btn{
      display: flex;
    height: 40px;
    border: none;
    padding: 16px 40px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    color: white;
    border: #0A8394 1px solid;
    background-color: #0A8394;
    font-family: "Noto Sans";
    font-size: 12px;
    font-weight: 400;
    }

  }
  
  /* Info Icon */
  .uploadcontact-info-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #0A8394;
  }
  
  /* Modal Heading */
  .uploadcontact-modal-heading {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
    font-weight: bold;
  }
  
  /* Drag-and-Drop Area */
  .uploadcontact-drag-drop-area {
    border: 2px dashed #007bff;
    border-radius: 8px;
    padding: 20px;
    background: #f9f9f9;
    cursor: pointer;
    text-align: center;
    margin-bottom: 20px;
  
    &:hover {
      background: #f0f8ff;
    }
  }
  
  .uploadcontact-drag-drop-message {
    font-size: 1rem;
    color: #555;
  
    .uploadcontact-browse-link {
      color: #0A8394;
      text-decoration: underline;
      cursor: pointer;
  
      &:hover {
        color: #0A8394;
      }
    }
  }
  
  .uploadcontact-selected-file {
    font-size: 1rem;
    color: #333;
  }
  
  /* Modal Footer Buttons */
  .uploadcontact-modal-footer {
    display: flex;
    justify-content: space-between;
  
    .uploadcontact-close-button,
    .uploadcontact-submit-button {
      background-color: #f5f5f5;
      border: 1px solid #ddd;
      border-radius: 4px;
      padding: 10px 20px;
      font-size: 1rem;
      cursor: pointer;
  
      &:hover {
        background-color: #e0e0e0;
      }
    }
  
    .uploadcontact-submit-button {
      background-color: #0A8394;
      color: #fff;
      border: none;
      &:hover {
        background-color: #0A8394;
      }
    }
  }
  