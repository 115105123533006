.Uploads {
  display: flex;
  flex-direction: column;
  height: 100%;
  .Header {
    height: 40px;
  }
  .ContentContainer {
    flex: 1;
  }
}
