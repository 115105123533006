.gstr3b-table-container {
  font-family: 'Noto Sans', sans-serif;
  border-radius: 16px;
  padding: 20px;
  width: 90%;
  min-height: 400px;
}

h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.header-line {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
}

.negative-delay {
  color: red;
}
