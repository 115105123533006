/* GstModal.css */

.ant-modal-content {
  font-family: -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.88);
  width: 500px; /* Adjust width if necessary */
}

.ant-modal-header, .ant-modal-title {
  font-family: inherit; /* Inherit font from the parent */
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

.ant-modal-body {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: left; /* Center items horizontally */
  justify-content: left; /* Center items vertically */
  pointer-events: auto; /* Enable pointer events */
}

.ant-modal-close {
  color: rgba(0, 0, 0, 0.88);
}

.ant-modal-close-icon {
  color: inherit;
}

.AddIntegration {
  text-align: center; /* Center text inside the div */
  font-size: 34px; /* Increase the font size */
  font-weight: bold; /* Make the text bold */
}

.AddIntegration img {
  display: block;
  margin: 0 auto; /* Center the image */
}

.AddIntegration p {
  font-family: inherit;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.88);
  margin-top: 24px;
}

.FormBody {
  width: 200%; /* Ensure form body takes full width */
  max-width: 450px; /* Set a max width for the form body */
}

.FormGroup {
  margin-bottom: 16px;
}

.FormGroup input {
  width: 100%;
}

.FormGroup .ant-btn {
  width: 100%;
  height: 42px; /* Ensure button matches input height */
}

.footer-link {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-family: inherit;
  font-size: 14px;
  line-height: 22px;
  color: rgb(10, 131, 148);
  cursor: pointer;
}

/* Default styles will remain unchanged */

/* For large screens (2000px and above) */
@media only screen and (min-width: 2000px) {
  .ant-modal-content {
    font-size: 21px;           /* Increase font size */
    line-height: 33px;         /* Adjust line-height */
    color: rgba(0, 0, 0, 0.88);
    width: 750px;              /* Increase modal width */
  }

  .ant-modal-header, .ant-modal-title {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }

  .ant-modal-body {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    display: flex;
    flex-direction: column;
    align-items: left;         /* Maintain alignment */
    justify-content: left;     /* Maintain alignment */
    pointer-events: auto;
  }

  .ant-modal-close {
    color: rgba(0, 0, 0, 0.88);
  }

  .ant-modal-close-icon {
    color: inherit;
  }

  .AddIntegration {
    font-size: 51px;           /* Increase font size */
    font-weight: bold;
  }

  .AddIntegration img {
    display: block;
    margin: 0 auto;
  }

  .AddIntegration p {
    font-size: 21px;           /* Increase font size */
    line-height: 33px;         /* Adjust line-height */
    margin-top: 36px;          /* Increase margin-top */
  }

  .FormBody {
    width: 200%;               /* Maintain full width */
    max-width: 675px;          /* Increase max width */
  }

  .FormGroup {
    margin-bottom: 24px;       /* Increase margin-bottom */
  }

  .FormGroup input {
    width: 100%;
  }

  .FormGroup .ant-btn {
    width: 100%;
    height: 63px;              /* Increase button height */
  }

  .footer-link {
    font-size: 21px;           /* Increase font size */
    line-height: 33px;         /* Adjust line-height */
    color: rgb(10, 131, 148);
    cursor: pointer;
  }
}

/* For small screens (768px and below) */
@media only screen and (max-width: 768px) {
  .ant-modal-content {
    font-size: 10px;           /* Decrease font size */
    line-height: 16px;         /* Adjust line-height */
    color: rgba(0, 0, 0, 0.88);
    width: 300px;              /* Decrease modal width */
  }

  .ant-modal-header, .ant-modal-title {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }

  .ant-modal-body {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    display: flex;
    flex-direction: column;
    align-items: left;         /* Maintain alignment */
    justify-content: left;     /* Maintain alignment */
    pointer-events: auto;
  }

  .ant-modal-close {
    color: rgba(0, 0, 0, 0.88);
  }

  .ant-modal-close-icon {
    color: inherit;
  }

  .AddIntegration {
    font-size: 17px;           /* Decrease font size */
    font-weight: bold;
  }

  .AddIntegration img {
    display: block;
    margin: 0 auto;
  }

  .AddIntegration p {
    font-size: 10px;           /* Decrease font size */
    line-height: 16px;         /* Adjust line-height */
    margin-top: 12px;          /* Decrease margin-top */
  }

  .FormBody {
    width: 100%;               /* Set to 100% width */
    max-width: 300px;          /* Decrease max width */
  }

  .FormGroup {
    margin-bottom: 8px;        /* Decrease margin-bottom */
  }

  .FormGroup input {
    width: 100%;
  }

  .FormGroup .ant-btn {
    width: 100%;
    height: 28px;              /* Decrease button height */
  }

  .footer-link {
    font-size: 10px;           /* Decrease font size */
    line-height: 16px;         /* Adjust line-height */
    color: rgb(10, 131, 148);
    cursor: pointer;
  }
}
