.GSTAddReview {
  padding: 24px;
  box-sizing: border-box;
  height: 100vh;
  .MainContainer {
    width: 100%;
    height: 100%;
    display: flex;

    .LeftContainer {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      .CompanyContainer {
        display: flex;
        align-items: center;
        img {
          width: 44px;
          object-fit: contain;
          gap: 24px;
        }
      }
    }
    .TopLogo {
      img {
        width: 120px;
        margin-right: 550px;
      }
      .MiniDivder {
        width: 72px;
        border-bottom: 2px solid var(--base-color-primary);
        margin-top: 6px;
        margin-left: 25px;
      }
    }

    .CarouselContainer {
      margin-top: 24px;
    }

    .RightContainer {
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 100%;
      .PanGstSelectionContainer {
        display: flex;
        align-items: center;
        border: 1px solid #E8EAF1;
        border-radius: 4px;
        padding: 5px;
        margin-top: 12px;
      }
      .TableContainer {
        width: 100%;
        height: 300px;
        margin-top: 13px;
      }
      .UploadContainer {
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
        .Header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 12px;
          width: 100%;
        }
        .UploaderWidget {
          width: 100%;
          margin-top: 12px;
        }
        .UploaderFooter {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-top: 12px;
        }
        .ActionContainer {
          display: flex;
          align-items: center;
          width: 100%;
          //justify-content: space-between;
          margin-top: 24px;
        }
      }
    }
  }
}
