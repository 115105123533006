.ReportLegacyView {
  display: flex;
  flex-direction: column;
  height: 100%;
  .PageHeaderContainer {
    height: 40px;
  }
  .MainContainer {
    flex: 1;
  }
}
