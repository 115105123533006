.GSTAddDone {
  padding: 24px;
  box-sizing: border-box;
  height: 100vh;

  .MainContainer {
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    .SuccessBlockContainer {
      width: 500px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }
  .TopLogo {
    img {
      width: 120px;
     margin-left: 65px;
    }
    .MiniDivder {
      width: 72px;
      border-bottom: 2px solid var(--base-color-primary);
      margin-top: 6px;
      margin-left: 70px;
    }
  }
}
