.PrimaryNav {
  width: 80px;
  height: 100%;
  padding: 6px;
  box-sizing: border-box;
  display: flex;
  .NavigationController {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    z-index: 9999;
    width: 100%;
    height: 100%;
    // border-right: 1px solid #d7e6f9;
    display: flex;
    flex-direction: column;
    padding: 2px;
    box-sizing: border-box;
    justify-content: space-between;
    background-color: #f8f9fb !important;
    .TopNavigation {
      .AppLogo {
        display: flex;
        justify-content: center;
        img {
          width: 74px;
          margin-top: 8px;
        }
      }

      .MenuContainer {
        margin-top: 42px;
        .MenuItem {
          width: 100%;
          height: 48px;
          border-radius: 6px;
          // padding: 0 12px;
          box-sizing: border-box;
          margin-bottom: 12px;
          opacity: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 0.4s;
          margin-bottom: 8px;
          p {
            color: #a3a6b7;
            margin-top: 1px;
            font-size: 10px;
          }
          svg {
            fill: #a3a6b7;
          }

          &:hover {
            background-color: #dbf0f2;
            svg {
              fill: var(--base-color-primary);
            }
            p {
              color: var(--base-color-primary);
            }
          }
          &.active {
            background-color: #dbf0f2;
            svg {
              fill: var(--base-color-primary);
            }
          }
        }
      }
    }
    .BottomNavigation {
      display: flex;
      flex-direction: column;
      .MenuItem {
        transition: all 0.4s;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        flex-direction: column;
        svg {
          font-size: 22px;
        }
        p {
          color: #4e535a;
          font-size: 10px;
          margin-top: 1px;
        }
        svg {
          fill: #4e535a;
        }
        &:hover {
          svg {
            fill: #4e535a;
          }
          p {
            color: #4e535a;
          }
        }
        &.active {
          svg {
            fill: var(--base-color-primary);
          }
          p {
            color: var(--base-color-primary);
          }
        }
        &:first-child {
          margin-bottom: 1.5rem;
        }
        &:last-child {
          cursor: pointer;
        }
      }
    }
  }
}

.PrimaryNavSubMenu {
  .WorkspaceInfoContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 1px solid var(--base-color-blue-hover);
    padding: 30px 20px;
    box-sizing: border-box;

    .LeftContent {
      display: flex;
      align-items: center;
      width: 100%;
      .IconBox {
        border-radius: 3px;
        opacity: 1;

        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          border-radius: 6px;
          object-fit: cover;
        }
      }
      .WorkspaceInfo {
        margin-left: 8px;
      }
    }
    .CollapseAction {
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
        rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
      opacity: 1;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 50%;
      width: 24px;
      height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.4s;
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }
    }
  }
  .MenuContainer {
    padding: 0 10px;
    margin-top: 8px;
    .MenuItem {
      width: 100%;
      height: 36px;
      padding-left: 12px;
      box-sizing: border-box;
      border-radius: 6px;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      transition: all 0.4s;
      margin-bottom: 8px;
      svg {
        fill: #a3a6b7;
      }
      p {
        color: #a3a6b7;
      }

      &:hover {
        background-color: #dbf0f2;
        svg {
          fill: var(--base-color-primary);
        }
        p {
          color: var(--base-color-primary) !important;
        }
      }
      &.active {
        background-color: #dbf0f2;
        svg {
          fill: var(--base-color-primary);
        }
        p {
          color: var(--base-color-primary) !important;
        }
      }
    }
  }
}
.UserAvatarInfoPrimaryNav {
  cursor: pointer;
  border-radius: 50%;
  width: fit-content;
  height: fit-content;
  position: relative;
  display: flex;
  align-items: center;

  .UserInfo {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      line-height: 1;
      margin-bottom: 2px;
      //   font-weight: 600;
    }
  }
}

// and design drawer modifier
.ant-drawer-body {
  padding: 0 !important;
}

ul {
  border-inline-end: none !important;
}

.ant-tabs-nav::before {
  border: none !important;
}
