.GSTAddReviewFinal {
  padding: 24px;
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  // justify-content: center;
  .MainContainer {
    width: 100%;
    height: 90%;
    display: flex;

    .LeftContainer {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      .CompanyContainer {
        display: flex;
        align-items: center;
        img {
          width: 44px;
          object-fit: contain;
          gap: 24px;
        }
      }
    }
    .TopLogo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 120px;
        margin-left: 65px;
        margin-top: 6px;
      }
      .MiniDivder {
        width: 72px;
        border-bottom: 2px solid var(--base-color-primary);
        margin-top: 6px;
        margin-left: 70px;
      }
    }
    .RightContainer {
      width: 85%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-top: 10px;
      height: 90%;
      margin-left: 120px;
      box-shadow: 0px 10px 15px 0px #e8eaf1;
      border-radius: 20px;
      padding: 20px;

      .SummaryContainer {
        display: flex;
        align-items: center;
        margin-top: 24px;
        justify-content: space-between;
        width: 100%;
        .SummaryCard {
          width: 16%;
          height: 50px;
          border: 1px solid #dfe0e7;
          background-color: white;
          border-radius: 4px;
          padding: 4px 8px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .PanGstSelectionContainer {
        display: flex;
        align-items: center;
        border: 1px solid #e8eaf1;
        border-radius: 4px;
        padding: 3px;
        margin-top: 12px;
      }
      .TableContainer {
        width: 100%;
        height: 100%;
        margin-top: 13px;
        color: "#72747D";
      }
      .UploadContainer {
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
        .Header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 12px;
          width: 100%;
        }
        .UploaderWidget {
          width: 100%;
          margin-top: 12px;
        }
        .UploaderFooter {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-top: 12px;
        }
        .ActionContainer {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
          margin-top: 24px;
        }
      }
    }
  }
}
