.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); // Transparent black background
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; // Ensure it stays above other elements
}

.email-confirmation-modal {
  background-color: #f8fdff;
  border: 1px solid #dfe3e6;
  border-radius: 8px;
  // padding: 20px;
  width: 700px;
  height: 400px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  color: #2c3e50;

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    display: flex;
  background-color: #e2f0f2;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

    h2 {
      font-size: 18px;
      margin: 0;
      font-weight: 300;
      color: #2aa198;
    }

    .close-button {
      background: none;
      border: none;
      font-size: 20px;
      cursor: pointer;
      color: #7f8c8d;

      &:hover {
        color: #2c3e50;
      }
    }
  }

  .modal-body {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-top: 60px;

    .icon-container {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      .check-icon {
        width: 30px;
        height: 30px;
        color: white;
      }
    }

    p {
      font-size: 20px;
      color: #2c3e50;
      margin: 0;
      font-weight: 800;
    }
  }

  .modal-footer {
    text-align: center;
    margin-top: 40px;

    .continue-button {
      background-color: #2aa198;
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      cursor: pointer;
      width: 500px;

      &:hover {
        background-color: #218c81;
      }
    }
  }
}
