.VendorInvite {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .invitepage-container {
    display: flex;
    padding: 20px;
    justify-content: center;
    justify-content: space-around;
    align-items: center;
    background-color: #f9f9f9;
    flex: 1;
    .invitepage-left {
      width: 30%;

      .invitepage-image {
        margin-bottom: 1.5rem;

        img {
          max-width: 100%;
          height: auto;
        }
      }

      .invitepage-title {
        font-size: 2rem;
        font-weight: bold;
        color: #333333;
      }

      .invitepage-description {
        font-size: 1rem;
        color: #666666;
        margin-top: 1rem;
      }
    }

    .invitepage-right {
      width: 33%;
      padding: 50px;
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

      .invitepage-form-container {
        .invitepage-header {
          .invitepage-tag {
            display: inline-block;
            background-color: white;
            color: #0a8394;
            font-size: 0.875rem;
            font-weight: 500;
            padding: 0.25rem 0.5rem;
            border-radius: 20px;
            border: 1px solid #0a8394;
            margin: 30px 0px;
          }

          .invitepage-form-title {
            font-size: 2rem;
            font-weight: 500;
            color: #333333;
            margin-bottom: 20px;
          }

          .invitepage-form-subtitle {
            font-size: 1.5rem;
            color: #333333;
            font-weight: 400;
            margin-bottom: 20px;
          }
        }

        .invitepage-form {
          .invitepage-input-group {
            margin-bottom: 1.5rem;

            label {
              display: block;
              font-size: 0.875rem;
              color: #666666;
              margin-bottom: 0.5rem;
            }

            input {
              width: 95%;
              padding: 0.75rem;
              font-size: 0.875rem;
              border: 1px solid #cccccc;
              border-radius: 5px;

              &:focus {
                border-color: #0a8394;
                outline: none;
              }
            }
          }

          .invitepage-button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            width: 100%;
            padding: 0.75rem;
            font-size: 1rem;
            font-weight: bold;
            color: #ffffff;
            background-color: #0a8394;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;
            margin-bottom: 30px;

            &:hover {
              background-color: #0a8394;
            }
          }
        }
      }
    }
  }
}
