.gstimage{
    width: 80%;
    height: 40vh;
}
.carousel-heading{
    color: var(--Primaty2-Blue-800, #126978);
    font-family: "Noto Sans";
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 10px;
}
.carousel-li{
    color: var(--Neutral-800, #3F4047);
font-family: "Noto Sans";
font-size: 14px;
margin-bottom: 10px;
letter-spacing: -0.161px;
width: 380px;
}

/* Container for the carousel */
.carousel-container {
    /* position: relative; */
    width: 100%;
    overflow: hidden;
    margin-left: 100px;
  }
  
  /* Carousel content, initially hidden */
  .carousel-content {
    display: flex;
    transition: transform 0.7s ease;
  }
  
  /* Each individual slide */
  .carousel-slide {
    min-width: 100%;
    /* text-align: center; */
  }
  
  /* Style for dots container */
.dots {
    display: flex;
    justify-content: center;  /* Center the dots horizontally */
    gap: 10px;
    margin-top: 50px;  /* Adjusts space between dots and the content */
    margin-right: 120px;
  }
  
  /* Individual dot style */
  .dot {
    width: 10px;
    height: 10px;
    background-color: #ccc;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  
  .dot {
    width: 10px;
    height: 10px;
    background-color: #ccc;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .dot.active {
    background-color: #126978;
  }
  