
/* --------------------------------------------------------------------------------------------------------------- */

@media only screen and (max-width : 480px){
  .vendor-navbar{
    display: flex !important;
    flex-direction: column !important;
  }

  .mainsection-vendor{
    padding: 19px !important;
    margin-top: 60px !important;
  }

  .dropdown{
    display: flex !important;
    flex-direction: column !important;
  
  }

  .cards-row{
    display: flex !important;
    flex-direction: column !important;
    width: 86% !important;
  }

  .Topdiv{
    width: 100% !important;
  }

  /* .smallcard1{
    width: 100% !important;
  }

  .smallcard2{
    width: 100% !important;
  } */


  

  .div2 {
    display: flex !important;
    flex-direction: column !important;

  }

  .div3{
    display: flex !important;
    flex-direction: column !important;
  }

  .div4{
    display: flex !important;
    flex-direction: column !important;
  }

  .div5{
    display: flex !important;
    flex-direction: column !important;
  }

  .map-chart{
    width: 88% !important;
    display: flex !important;
    flex-direction: column !important;
    height: 652px !important;
  
  }
  .rightContainer{
    width: 88% !important;
  }
  .addcred-card{
    width: 100% !important;
    margin: 0 !important;
  }

  .barchart{
    width: 100% !important;
  }

  .right-gstr1{
    width: 100% !important;
  }

  .right-gstr3b{
    width: 100% !important;
  }

  .hsndiv{
    width: 100% !important;
  }

  .liachart{
    width: 100% !important;
  }

  .mainsection-vendor{
    width: 100% !important;
  }


  
}


@media only screen and (min-width: 480px) and (max-width: 820px){
  .vendor-navbar{
    display: flex !important;
    flex-direction: column !important;
  }

  .cards-row{
    flex-wrap: wrap !important;
    width: 100% !important;
    justify-content: space-between !important;
  }

  .smallcard1{
    width: 25% !important;
  }

  .smallcard2{
    width: 59% !important; 
  }

  .div2{
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
  }

  .map-chart{
    width: 100% !important;
  }

  .rightContainer{
    width: 40% !important;
  }

  .addcred-card{
    width: 51% !important;
  }

  /* .div3{
    gap: 10px !important;
  }

  .div4{
    gap: 10px !important;
  }

  .div5{
    gap: 10px !important;
  } */
}

@media only screen and (min-width : 821px) and (max-width: 921px){
  .vendor-navbar{
    display: flex !important;
    flex-direction: column !important;
  }

  .cards-row{
    flex-wrap: wrap !important;
    width: 100% !important;
    justify-content: space-between !important;
  }

  .smallcard1{
    width: 25% !important;
  }

  .smallcard2{
    width: 59% !important; 
  }

  .div2{
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
  }

  .map-chart{
    width: 100% !important;
  }

  .rightContainer{
    width: 40% !important;
  }

  .addcred-card{
    width: 51% !important;
    margin-left: 50px !important;
  }

  /* .div3{
    gap: 10px !important;
  }

  .div4{
    gap: 10px !important;
  }

  .div5{
    gap: 10px !important;
  } */
}

@media only screen and (min-width : 921px) and (max-width: 1024px){
  .vendor-navbar{
    display: flex !important;
    flex-direction: column !important;
  }

  .cards-row{
    flex-wrap: wrap !important;
    width: 100% !important;
    justify-content: space-between !important;
  }

  .smallcard1{
    width: 25% !important;
  }

  .smallcard2{
    width: 59% !important; 
    justify-content: space-between !important;
  }

  .div2{
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .map-chart{
    width: 100% !important;
  }

  .rightContainer{
    width: 40% !important;
  }

  .addcred-card{
    width: 51% !important;
    margin-left: 50px !important;
  }

  /* .div3{
    gap: 10px !important;
  }

  .div4{
    gap: 10px !important;
  }

  .div5{
    gap: 10px !important;
  } */
}

@media only screen and (min-width : 1024px) and (max-width: 1200px){
  .vendor-navbar{
    display: flex !important;
    flex-direction: column !important;
  }

  .cards-row{
    flex-wrap: wrap !important;
    width: 100% !important;
  }

  .smallcard1{
    width: 25% !important;
  }

  .smallcard2{
    width: 59% !important; 
  }

  .div2{
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .map-chart{
    width: 100% !important;
  }

  .rightContainer{
    width: 40% !important;
  }

  .addcred-card{
    width: 51% !important;
    margin-left: 50px !important;

  }

  /* .div3{
    gap: 10px !important;
  }

  .div4{
    gap: 10px !important;
  }

  .div5{
    gap: 10px !important;
  } */

  .maps-container{
    width: 69%  !important;
    height: 350px !important;
    position: relative !important;
    margin-top: -41px !important;
  }
}

.mainsection-vendor{
  background-color: #fafafa;
  padding: 24px;
  box-sizing: border-box;
  padding-top: 48px;
  width: 100%;
}
.vendor-navbar{
  display: flex;
  justify-content: space-between;
  position: 'relative'
}
.dropdown{
  display: flex;
  gap: 30px;
  margin-top: 40px;
}
.mainheader{
  color: #0A8394;
  font-weight: bold;
  font-size: larger;
}
.mainheaderstatus{
  color: #034619;
  font-weight: bold;
  font-size: larger;
}


.mainheader1{
  color: #1E1B39;
text-align: center;
}
.Topdiv{
  padding-top: 30px;
  display: flex;
  gap: 30px;
}

.rightContainer{
  flex: 1 1 100%; /* Each card takes 100% width */
  box-sizing: border-box; /* Include padding and border in width */

}
.smallcard1{
  border-radius: 16px;
  background: #fff;
  box-shadow:  0px 2px 12px 0px rgba(13, 10, 44, 0.15);
  width: 20%;
  height: auto;
  padding: 20px;
  flex: 1 1 calc(50% - 16px); /* Take up 50% width minus the gap */
  box-sizing: border-box; /* Ensure padding and border are included in width */
  padding: 16px;
  overflow: hidden; /* Ensures content stays inside the card */
}
.right-gstr1{
  width: 100%;
  border-radius: 16px;
  background: var(--Neutral-50, #FFF);
  box-shadow: 0px 2px 12px 0px rgba(13, 10, 44, 0.15);
}
.hsndiv{
  width: 100%;
  border-radius: 16px;
  background: var(--Neutral-50, #FFF);
  box-shadow: 0px 2px 12px 0px rgba(13, 10, 44, 0.15);
}
.right-gstr3b{
  width: 100%;
  border-radius: 16px;
  background: var(--Neutral-50, #FFF);
  box-shadow: 0px 2px 12px 0px rgba(13, 10, 44, 0.15);
}
.cards-row{
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
}
.smallcard2{
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 2px 12px 0px rgba(13, 10, 44, 0.15);
  width: 30%;
  height: auto;
  padding: 20px;
  flex: 1 1 calc(50% - 16px); /* Take up 50% width minus the gap */
  box-sizing: border-box; /* Ensure padding and border are included in width */
  padding: 16px;
  overflow: hidden;
  

}
.c-des{
  color: #9291A5;
  font-size: 15px;
}
.c-head{
  color: #0A8394;
  font-weight: 700;
  font-size: 20px;

}
.cards-row1{
  display: flex;
  gap: 20px;
  padding-top: 20px;
}
.map-chart{
  display: flex;
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 2px 12px 0px rgba(13, 10, 44, 0.15);
  padding: 20px;
  width: 43%;
  height: 338px;
}
.div2{
  padding-top: 30px;
  display: flex;
  width: 100%;
  gap: 10px;
  
}
.div3{
  padding-top: 30px ;
  display: flex;
  
  gap: 30px;
  width: 100%;
}
.div4{
  padding-top: 30px ;
  display: flex;
  gap: 30px;
  width: 100%;
}
.barchart{
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 2px 12px 0px rgba(13, 10, 44, 0.15);
  width: 55%;
}
.div5{
  padding-top: 30px ;
  display: flex;
  gap:30px;
  width: 100%;
}
.liachart{
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 2px 12px 0px rgba(13, 10, 44, 0.15);
  width: 59%;
}
.vendor-img{
  height: 20px;
  width: 20px;
}
.img-div{
  border-radius: 50%;
  background-color: #e1f0f2;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pan-dropdown-container1{
  display: flex;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 12px 0px rgba(13, 10, 44, 0.15);
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  gap: 3px;
  height: 80%;
}
.gstin-dropdown-container{
  display: flex;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 12px 0px rgba(13, 10, 44, 0.15);
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  gap: 3px;
  height: 80%;
}
.custom-select-container{
  width: 220px;
  border-radius: 15px;
}

.dropdown-label{
  font-size: 14px;
  color:  #9291A5;
  padding: 8px;
}
.addcred-card{
  flex: 1 1 100%; /* Each card takes 100% width */
  box-sizing: border-box; /* Include padding and border in width */
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 2px 12px 0px rgba(13, 10, 44, 0.15);
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 380px;
  margin-left: 40px;
  gap: 20px;
}
.gstimg{
  align-self: flex-start; /* Aligns the image at the start (top) */
  width: 100%; /* Ensures the image fits within the card */
  height: 55%;
}
.addcred-btn1{
  background-color: #0A8394;
  width: 70%;
  margin-bottom: 20px;
  color: #fafafa;
  padding: 15px 20px;
  border: none;
  border-radius: 8px;
}



/* Toggle Button - Larger Size */
.switch {
  position: relative;
  display: inline-block;
  width: 60px; /* Increased width */
  height: 34px; /* Increased height */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px; /* Match height for rounded effect */
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px; /* Adjusted size for the toggle circle */
  width: 26px; /* Adjusted size for the toggle circle */
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #0A8394;
}

input:checked + .slider:before {
  transform: translateX(26px); /* Adjust for larger size */
}


.smallcard3{
  border-radius: 16px;
  background: #fff;
  box-shadow:  0px 2px 12px 0px rgba(13, 10, 44, 0.15);
  width: 20%;
  height: auto;
  padding: 20px;
  flex: 1 1 calc(50% - 16px); /* Take up 50% width minus the gap */
  box-sizing: border-box; /* Ensure padding and border are included in width */
  padding: 16px;
  overflow: hidden; /* Ensures content stays inside the card */
  display: flex;
  gap: 20px;
  align-items: center;
}

/* Custom modal styling */
.modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  margin: 0 auto;
  text-align: center;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
