.GlobalUploader {
  position: absolute;
  bottom: 24px;
  right: 24px;
  width: 360px;
  display: flex;
  align-items: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  height: 78px;
  border-radius: 8px;
  padding: 12px 18px;
  box-sizing: border-box;
  justify-content: space-between;
  background-color: white;
  .Left {
    flex: 1;
  }

  .Right {
    width: 20px;
    margin-left: 24px;
  }
}
