.Credentials {
  width: 100%;
  height: 100%;
  .ScreenContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .TableContainer {
      flex: 1;
      .ag-root-wrapper {
        border: none !important;
      }
    }
    .TabContainer {
      height: 100%;
      margin-top: 12px;
    }
  }
}

//ant-design modifiers

.ant-input-outlined {
  // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.3rem`,
  border-width: 1 !important;
  box-shadow: none;
  &:hover {
    // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.3rem`,
    // border-width: 3px;
    box-shadow: rgba(50, 104, 185, 0.25) 0px 0px 0px 0.25rem !important;
  }
  &:focus {
    // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.3rem`,
    // border-width: 3px !important;
    box-shadow: rgba(50, 104, 185, 0.25) 0px 0px 0px 0.25rem !important;
  }
}
