.piediv{
  display: flex;
}
/* .pieli{
  font-size: 10px;
}
.pieul{
  padding-top: 100px;
}
   */

   .pieul {
    list-style: none; /* Remove default list styling */
    padding-top: 100px;
  }
  
  .pieli {
    display: flex; /* Use flexbox to align items */
    align-items: center; /* Center items vertically */
    margin-bottom: 10px; /* Add space between items */
    font-size: 12px;
  }
  
  .pieli::before {
    content: ''; /* No text content, just a shape */
    display: inline-block;
    width: 10px; /* Size of the circle */
    height: 10px; /* Size of the circle */
    border-radius: 50%; /* Makes it a circle */
    margin-right: 10px; /* Space between the circle and the text */
  }
  
  /* Different colors for each item */
  .pieli:nth-child(1)::before {
    background-color: #48C497; /* Blue */
  }
  
  .pieli:nth-child(2)::before {
    background-color: #F5C956; /* Orange */
  }
  
  .pieli:nth-child(3)::before {
    background-color: #F06B4B; /* Green */
  }

 .header-line2 {
  border-top: 2px solid #e1e1e1;
  margin-top: -10px;
  width: 430px;
  position: relative;
}