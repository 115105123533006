.CRMTransfers {
  display: flex;
  height: 100%;
  width: 500px;
  box-shadow: 1px 1px 1px 1px gray;

  .MainContent {
    height: 100%;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    // padding: 24px;
    box-sizing: border-box;
    padding-right: 48px;
    padding-top: 24px;
    .ItemRow {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 20px;
      margin-bottom: 24px;
      justify-content: space-between;
    }
  }
}
