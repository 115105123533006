.OnboardingInviteUser {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .backgroundIllustration {
    position: absolute;
    top: 0;
    width: 100%;
    bottom: 0;
    right: 0;
    height: 100vh;
    width: 40%;
    opacity: 0.9;
    z-index: 1;
  }
  .HeaderContainer {
    z-index: 2;
  }
  .WrapperContainer {
    display: flex;

    flex: 1;
    z-index: 3;
    .LeftSection {
      width: 65%;
      display: flex;
      flex-direction: column;
      padding: 32px;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      .FormGroup {
        margin-top: 28px;
        width: 520px;
        .FieldContainer {
          margin-bottom: 24px;
        }
        .ActionContainer {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
    .RightSection {
      width: 35%;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      justify-content: space-between;
      padding: 11% 0;
      box-sizing: border-box;
      padding-left: 6%;
      padding-right: 6%;
      position: relative;
      .GSTIllustration {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;
        color: rgba(255, 255, 255, 0.657);
        font-size: 14rem;
        opacity: 0.1;
      }
      .rightIllustration {
        width: 492px;
        margin-top: 72px;
      }
    }
  }
  .BottomReference {
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    box-sizing: border-box;
    .ImageContainer {
      width: 35%;
    }

    .ComplianceContainer {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        opacity: 0.9;
        margin-right: 16px;
      }
    }
  }
}
