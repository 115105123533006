.AddIntegration {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  .IntegrationImage {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 24px;
    flex-direction: column;
    align-items: center;
  }
  .FormBody {
    margin-top: 24px;
    width: 100%;
    .FormGroup {
      width: 100%;
      margin-bottom: 24px;
    }
  }
}
