.FinkAnalytics {
  width: 100%;
  height: 100%;

  .FASetup {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .SearchBoxContainer {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
