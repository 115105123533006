.Hotels {
  // padding: 24px;
  // flex: 1;
  height: 100%;
  .ScreenContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .TableContainer {
      flex: 1;
      overflow-y: auto;
      .CardList {
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        // height: 100%;
        flex-wrap: wrap;
        .IntegrationCard {
          width: 310px;
          height: 168px;
          background-color: white;
          border-radius: 12px;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
          padding: 22px 16px;
          box-sizing: border-box;
          flex-direction: column;
          justify-content: space-between;
          display: flex;
          cursor: pointer;
          margin-right: 20px;
          margin-bottom: 20px;
          transition: transform 0.4s;
          border: 2px solid var(--base-color-gray);
          &:hover {
            border: 2px solid var(--base-color-primary);
            transform: scale(1.05);
          }
          .CardTopContainer {
            display: flex;
            justify-content: space-between;
            height: auto;
            align-items: flex-start;
            .ImageContainer {
              display: flex;
              align-items: center;
              img {
                width: 22px;
                margin-right: 8px;
              }
            }
          }
          .CardDescription {
          }
        }
        .cardAdd {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 158px;
          box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
            rgba(27, 31, 35, 0.15) 0px 0px 0px 1px !important;
          border: 0;
          &:hover {
            transform: none;
            border: none;
          }
        }
      }
    }
    .TabContainer {
      height: 100%;
      margin-top: 12px;
    }
  }
  iframe {
    border: none !important;
  }
}
