.TableViewManager {
  display: flex;
  align-items: center;
  .eidtCircleButton {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    cursor: pointer;
  }
}
