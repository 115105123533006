.GstCredLanding {
  box-sizing: border-box;
  height: 100vh;
  background-color: white;
  .Header {
    padding: 16px 44px;
  }
  .MainContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 44px;
    box-sizing: border-box;
    justify-content: center;

    .EntityMetaContainer {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      margin-top: 12px;

      .EntitiyContainerLeft {
        width: 45%;
        .ClientInfoContainer {
          margin-top: 24px;
        }
        .TimerContainer {
          border: 1px solid #edeff5;
          background: #f8f9fb;
          padding: 10px;
          box-sizing: border-box;
          margin-top: 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .TimeCountDownContainer {
            display: flex;
            align-items: center;
            gap: 12px;
            .TimeBox {
              width: 56px;
              height: 56px;
              border-radius: 50%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              background-color: white;
              box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            }
          }
        }
      }
      .EntityContainerRight {
        width: 55%;
        display: flex;
        justify-content: flex-start;
      }
    }
    .StepContainer {
      display: flex;
      align-items: center;
      margin-top: 24px;
      width: 100%;
      justify-content: space-between;
      .StepBox {
        width: 30%;

        border-radius: 5px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        background-color: white;
        padding: 20px;
        box-sizing: border-box;
        .StepStatus {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 12px;
        }
      }
    }
    .CertificateContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 44px;
    }
  }
}

.datamaps-legend {
  display: none;
}

#root-svg-group > g:last-child {
  display: none;
}

.datamaps-subunit:hover {
  fill: #00ff00; /* Your hover color */
}
