
.modal-submit-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 8px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
  }
  .report-add-btn{
    background-color: #4CAF50;
    color: white;
    border: none;
    text-align: center;
    text-decoration: none;
    height: 40px;
    display: inline-block;
    font-size: 16px;
    padding: 0px 10px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
  }
  
.modal-close-button {
  background-color: red;
  color: white;
  border: none;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}
  .form-box{
    margin-bottom: 10px;
  }

  .clickable-cell {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
  
  /* Modal Styling */
  .custom-modal-report {
    position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
    padding: 20px;
    width: 800px;
    max-height: 800px;
    background-color: #fff;
    border-radius: 8px;
    margin: auto;
    overflow: auto;
    outline: none;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%; /* Adjust as needed */
  max-width: 600px; /* Adjust as needed */
  margin: auto;
  }
  .workspaceinput{
    border-radius: 3px;
    padding: 3px;
    margin-left: 10px;
  }
  .worksdpacelabel{
    font-size: 30px;
  }
  
  .custom-form-modal-report {
    position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
    padding: 20px;
    width: 500px;
    background-color: #fff;
    border-radius: 8px;
    margin: auto;
    overflow: auto;
    outline: none;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .ReactModal__Overlay {
    z-index: 1000;
  }
  
  .report-form {
    display: flex;
    flex-direction: column;
  }
  
  .report-label {
    margin: 10px 0;
    font-size: 16px;
  }
  
  .report-input
    {
    padding: 8px;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid black !important;
  }
  
  .report-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  /* Ensure the modal container covers the table modal */
  

  
  .modal-button {
    padding: 10px 20px;
    margin-top: 20px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .modal-button:hover {
    background-color: #0056b3;
  }
  

  
  .ag-theme-alpine button.edit-button {
    color: blue;
  }
  
  .ag-theme-alpine button.delete-button {
    color: red;
  }
  
  
  /* Button Styles */
  button.modal-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button.modal-button:hover {
    background-color: #45a049;
  }
  
  button.edit-button {
    color: blue;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  button.edit-button:hover {
    text-decoration: underline;
  }
  
  button.delete-button {
    color: red;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  button.delete-button:hover {
    text-decoration: underline;
  }
  


  .custom-modal-pan {
    content: '';
    position: relative;
    margin: auto;
    top: 20px;
    width: 80%;
    max-width: 600px; /* Set max width */
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .custom-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Overlay background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-close-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  
 /* CreatePanModal CSS */
.create-pan-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 500px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.create-pan-modal h2 {
  margin-bottom: 20px;
  text-align: center;
}

.create-pan-modal label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.create-pan-modal input {
  /* width: 100%; */
  padding: 8px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #ccc !important;
}

.create-pan-modal .button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.create-pan-modal button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.create-pan-modal button:hover {
  background-color: #0056b3;
}

.create-pan-modal .close-button {
  position: absolute;
  background-color: #007bff;
  top: 10px;
  right: 10px;
  border: none;
  font-size: 16px;
  cursor: pointer;
}



/* CreateWorkspaceModal.css */
.create-workspace-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 400px;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-group {
  margin-bottom: 15px;
  width: 100%;
}

.workspacecreateinput {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc !important;
  border-radius: 4px;
}

.close-button {
  background: none;
  border: none;
  font-size: 15px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: red;
  color: white;
}

.submit-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #45a049;
}

.workspace-delete-btn{
  background-color: red;
  color: #fff;
  border-radius: 5px;
}
/* GstinModel.css */
.gstin-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 800px; /* Adjust size as needed */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}


/* App.css */
.pan-list {
  max-height: 200px;
  overflow-y: scroll;
}

.pan-list div {
  display: flex;
  align-items: center; /* Aligns checkbox and label vertically */
  margin-bottom: 4px; /* Small gap between each checkbox-item, adjust as needed */
}

.pan-list input[type="checkbox"] {
  margin: 0; /* Removes any default margin */
  padding: 0; /* Removes any default padding */
}

.pan-list label {
  margin: 0; /* Ensures there's no margin on the label */
}
.loader-container {
  position: absolute; /* Position the loader absolutely within the grid */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center it */
  z-index: 1000; /* Ensure it appears above the grid */
}
.gstin-save-button{
  background-color: #28a745; /* Green color */
  color: white;
  border: none;
  padding: 8px 30px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.button-container{
  display: flex;
  margin-bottom: 30px;
  margin-top: 10px;
  gap: 10px;
}