.BookingDataPreview {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .WrapperContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 0;
    box-sizing: border-box;
    padding: 24px;
    box-sizing: border-box;
    justify-content: space-between;
    flex: 1;

    .RowContainer {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      .Column {
        // box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        //   rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
        padding: 16px;
        box-sizing: border-box;
        border-radius: 8px;
        background-color: white;
        height: 96px;
      }
    }
  }
}
