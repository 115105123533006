.email-modal-overlay {
  position: fixed;
  bottom: 0;
  right: 0;
  width: auto;  /* Adjust width as needed */
  height: auto; /* Adjust height as needed */
  background-color: transparent;
  display: flex;
  justify-content: flex-end; /* Align content to the right */
  align-items: flex-end; /* Align content to the bottom */
  z-index: 1000;
}


.email-modal {
  background: #f8fbfc;
  width: 800px;
  min-height: 580px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}


.email-modal-close {
  font-size: 30px;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--Primaty2-Blue-700, #0A8394);

}
.email-top{
  display: flex;
  background-color: #e2f0f2;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.email-modal-header {
  font-size: 20px;
  color: var(--Primaty2-Blue-700, #0A8394);
}
.email-top-right{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
/* Dropdown styling */
.professional-dropdown {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  color: #555;
  background-color: #fff;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

/* Hover and focus effects on dropdown */
.professional-dropdown:hover,
.professional-dropdown:focus {
  border-color: #007bff;
  outline: none;
}

.email-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  overflow: auto; /* Enables scrolling */
}

/* Hide scrollbar for Webkit browsers (Chrome, Safari, etc.) */
.email-form::-webkit-scrollbar {
  width: 0; /* Hides the scrollbar */
  height: 0; /* Hides the horizontal scrollbar */
}

/* Hide scrollbar for Firefox */
.email-form {
  scrollbar-width: none; /* Hides scrollbar in Firefox */
  -ms-overflow-style: none; /* Hides scrollbar in Internet Explorer and Edge */
}


.email-suggestions-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  margin-top: 5px;
}

.email-suggestions-dropdown li {
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.email-suggestions-dropdown li:hover {
  background-color: #007bff;
  color: white;
}

.email-suggestions-dropdown li.selected {
  background-color: #007bff;
  color: white;
}




.email-field {
  display: flex;
  position: relative;
  gap: 5px;
  align-items: center;
  border-bottom: 1px solid rgba(0, 44, 46, 0.12);
  

  .email-tags-container{
    display: flex;
    flex-wrap: wrap;
    gap:5px;
    // margin-left: 25px;
    
  }


  .email-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .email-tag {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    padding: 5px 10px;
    border-radius: 16px;
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .email-tag button {
    background: none;
    border: none;
    margin-left: 5px;
    cursor: pointer;
    color: #ff0000;
    font-weight: bold;
  }
  

  label {
    font-size: 14px;
    color: #6c757d;
    width: 10%;
  }

  .email-input {
    padding: 10px;
    border-radius: 5px;
    color: #495057;
  }

  .email-tag {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 5px 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .email-tag-icon {
    margin-right: 8px;
    background-color: #DFF9E5;
    border-radius: 50%;
  }

  .email-ccext {
    position: absolute;
    right: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: #333; /* Adjust color as needed */
    display: flex;
    gap: 10px;
    cursor: pointer;
  }

  input {
    width: 78%;
    padding: 10px;
    border: none;
    background-color: transparent;
    border-radius: 5px;
    font-size: 14px;

    &:focus {
      outline: none; /* Removes the default focus outline */
      box-shadow: none; /* Removes any focus shadow */
    }
  }
  
}


.email-body-container {
  margin-top: 10px; /* Add space above the body container */
  display: flex;
}

.email-body {
  width: 100%; /* Full width of the container */
  height: 300px; /* Adjust height as needed */
  padding: 10px; /* Add padding for better readability */
  font-size: 14px; /* Make the text readable */
  font-family: Arial, sans-serif; /* Consistent font with other inputs */
  border: none;
  background-color: transparent;
  box-sizing: border-box; /* Include padding in width/height */
  resize: vertical; /* Allow vertical resizing only */
  text-align: left; /* Left-align text */
  white-space: pre-wrap;
  line-height: 1.5;
}

.email-body:focus {
  border: none;
  background-color: transparent;
  outline: none;  /* Removes the default focus outline */
}


.attachments-list {
  margin-top: 10px;
  font-size: 14px;

  
.attachment-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.attachment-name {
  margin-right: 8px;
  font-weight: bold;
}

.attachment-size {
  color: gray;
  margin-right: 8px;
}

.remove-attachment-btn {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 16px;
}
}


.email-footer {
  display: flex;
  justify-content: space-between;
  // justify-content: center;
  align-items: center;

  .email-footer-left{
    display: flex;
    // justify-content: center;
    align-items: center;
    gap: 20px;

  }

  .email-send-btn {
    background-color: #0A8394;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    &:hover {
      background-color: #0A8394;
    }
  }
}
