.Settings {
  height: 100vh;
  .ScreenContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    .TableContainer {
      flex: 1;
      .TabViewContainer {
        display: flex;
        flex-direction: column;
        height: 100%;
        .TabContainer {
          height: 66px;
          padding: 8px 24px;
        }
        .TabContentContainer {
          flex: 1;
        }
      }
    }
  }
}

.ant-collapse-item {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  background: white !important;
}
