.vendorfollowup-dropdown-container {
  width: 250px;
  font-family: Arial, sans-serif;
  position: relative;
  color: #0A8394;
}

.dashboard-right-header {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.vendor-header {
  display: flex;
  justify-content: space-between;
}

.eligiblestatus {
  display: flex;
  align-items: center;
  border-radius: 2px;
  border: 1px solid var(--Neutral-200, #EDEFF5);
  height: 25px;
  padding: 0px 10px;
}

.vendor-btn {
  display: flex;
  height: 40px;
  border: none;
  padding: 16px 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  color: black;
  border: #0A8394 1px solid;
  background-color: transparent;
  font-family: "Noto Sans";
  font-size: 12px;
  font-weight: 400;
}
.remove-filter{
  background-color: #0A8394;
  color: #f0f0f0;
  padding: 5px 15px;
  border: #0A8394 1px solid;
  border-radius: 4px;
  font-family: "Noto Sans";
  margin-right: 5px;
}

.vendor-right {
  display: flex;
  gap: 20px;
}

.vendor-header-left {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.vendorfollowup-dropdown-header {
  background: #fff;
  border: 1px solid #0A8394;
  padding: 8px 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  font-size: 15px;
}

&::-webkit-scrollbar {
  width: 0;
  height: 0;
}


.vendorfollowup-dropdown-header.active {
  border-color: #0A8394;
}

.vendorfollowup-dropdown-icon {
  font-size: 12px;
  color: #0A8394;
}

.vendorfollowup-dropdown-body {
  position: absolute;
  width: 100%;
  background: #fff;
  border: 1px solid #0A8394;
  border-top: none;
  max-height: 300px;
  overflow-y: auto;
  z-index: 10;
  border-radius: 0 0 6px 6px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.vendorfollowup-dropdown-search {
  margin: 10px;
  padding: 8px;
  border: 1px solid #0A8394;
  border-radius: 4px;
  width: 85%;

}

.vendorfollowup-dropdown-item {
  padding: 10px 15px;
  cursor: pointer;
  transition: background 0.2s;
}

.vendorfollowup-dropdown-item:hover {
  background: #f0f0f0;
}

.vendorfollowup-dropdown-item.no-options {
  color: #999;
  text-align: center;
}


.action-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  color: #0A8394;
  cursor: pointer;
  text-decoration: underline;
}


.contact-status {
  &.completed {
    color: #1E7734;
    font-weight: 400;
    background-color: #C0F2CC;
    border-radius: 20px;
    height: 25px;
    width: 100px;
    gap: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.contact {
    color: #0A8394;
    font-weight: 400;
    border: 2px solid #0A8394;
    border-radius: 4px;
    height: 30px;
    width: 130px;
    gap: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.pending {
    color: #2355D2;
    background-color: #C1DCFC;
    font-weight: 400;
    border-radius: 20px;
    height: 25px;
    width: 80px;
    gap: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;

  &.green {
    background: #4caf50;
  }

  &.red {
    background: #f44336;
  }

  &.orange {
    background-color: orange;
  }
}

.vendor-header {
  display: flex;
  gap: 10px;
  align-items: center;
}

h1 {
  font-size: 20px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  color: var(--Neutral-800, #3F4047);
}

.vendor-back-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0A8394;
  background-color: transparent;
  border-radius: 5px;
  padding: 5px;
}

.groupby-btn {
  display: flex;
  height: 40px;
  border: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 4px;
  background-color: #0A8394;
  border: none;
  color: #f0f0f0;
  height: 40px;
  display: flex;
  border: none;
  padding: 16px 40px !important;
  justify-content: center;
  align-items: center;
}

/* display: flex;
    height: 40px;
    border: none;
    padding: 16px 40px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 4px;
    background: var(--Primaty2-Blue-700, #0A8394);
    color: var(--Neutral-50, #FFF);
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; */
.action-tick {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  color: #0A8394;
  cursor: pointer;
}

.side-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  background: #f9f9f9;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 20px;
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.side-menu.open {
  transform: translateX(0);
}

.side-menu.closed {
  transform: translateX(100%);
}

.invoiceimg {
  height: 15px;
  width: 15px;
}

.center-toast {
  display: flex !important;
  justify-content: center !important;
  /* Horizontal centering */
  align-items: center !important;
  /* Vertical centering */
  margin: 0 auto !important;
  text-align: center;
  width: fit-content !important;
  /* Adjust width to fit the content */
  transform: translateY(50%) !important;
  /* Slight adjustment for proper vertical positioning */
}

/* Toggle Buttons Container */
.toggle-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
}

/* Toggle Switch */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 300px;
  /* Adjust width for both names */
  height: 50px;
  background-color: #ffffff;
  /* White background */
  border-radius: 10px;
  border: 2px solid #0A8394;
  /* Light green border */
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
  overflow: hidden;
}






/* Add this to your CSS file */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.2);
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* CSS for loader overlay */
.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  /* Semi-transparent overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  /* Ensure it is above the chart */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.2);
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.chart-container {
  position: relative;
  /* Ensure the loader is positioned correctly over the chart */
}

/* MonthDropdown.css */
.select-container {
  position: relative;
  width: 180px;
  font-family: Arial, sans-serif;
  margin-bottom: 20px;
}

select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  font-size: 16px;
  background-color: #0A8394;
  color: white;
  border: none;
  cursor: pointer;
  appearance: none;
  /* Remove default dropdown arrow */
  text-align: center;
}

select:focus {
  outline: none;
}

option {
  background-color: white;
  color: black;
  padding: 10px;
  font-size: 14px;
}

option:hover {
  background-color: #f1f1f1;
}

option:active {
  background-color: #e1e1e1;
}

.select-container::after {
  content: '▼';
  font-size: 16px;
  color: white;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

/* Center align the header text */
/* .ag-header-cell-label {
  justify-content: start;
}

.ag-cell {
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: start;
} */
.months-dropdown-container {
  position: relative;
  padding: 8px;
  border-radius: 4px;
  font-size: 16px;
  background-color: #0A8394;
  color: white
}

.months-dropdown-button {
  background-color: #0A8394;
  color: white;
  border: 1px solid #0A8394;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
  position: relative; /* Ensure button has a positioning context */
  z-index: 10; /* Make sure button is above other elements */
}

.months-dropdown-menu {
  position: absolute;
  left: 0;
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 92%;
  padding: 10px;
  max-height: 400px;
  overflow-y: auto;
  z-index: 20; /* Ensures dropdown is above other elements */
  
}

.months-checkbox-option {
  margin-bottom: 10px;
}

.months-submit-button {
  padding: 8px 16px;
  background-color: #0A8394;
  color: white;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-top: 15px;
}

.months-dropdown-button .dropdown-toggle {
  font-size: 18px;
  margin-left: 10px;
}

.months-submit-button:hover {
  background-color: #0A8394;
}





/* Modal overlay (background dim) */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center; /* Center the modal in the middle of the screen */
  z-index: 1000;
}

/* Modal content */
.modal-content-pivot {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  width: 75%;
  min-height: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
  margin-top: 10px;
}

/* Close button */
.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}
.remove-filter{
  display: flex;
  height: 40px;
  border: none;
  padding: 16px 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  color: white;
  background-color: #0A8394;
  font-family: "Noto Sans";
  font-size: 12px;
  font-weight: 400;
}
