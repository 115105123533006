.dashboard {
  display: flex;
  flex-direction: column;

  .dashboard-header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .finklogo{
      width: 10%;
      height: 10%;
    }

    .dashboard-right-header{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      padding-right: 40px;
    }

    .header-btn-top {
      // margin: 40px 60px 10px 10px;
      width: 160px;
      // padding: 9px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      display: flex;
      color: #0A8394;
      border: 1px solid;
    }
    
  }

  .dashboard-header img {
    width: 60px;
    padding: 27px 10px 10px 80px;
  }


  .container-first-row {
    width: 94%;
    margin: 0px 60px;
    display: flex;
    box-sizing: border-box;
    padding: 28px 32px;
    column-gap: 53px;
    border-radius: 20px;
    background: var(--Neutral-50, #FFF);
    box-shadow: 0px 4px 15px 0px #E8EAF1;


    .header {
      margin-bottom: 32px;
      width: 50%;

      .integration-badge {
        display: flex;
        padding: 4px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        width: 12%;
        border-radius: 72px;
        color: #0A8394;
        border: 1px solid;
        font-family: "Noto Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .title-container {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 16px;

        .icon {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f3f4f6;
          border-radius: 4px;
          color: #4b5563;
        }

        h2 {
          font-family: "Noto Sans";
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .description {
        margin-top: 16px;
        color: #3F4047;
        font-family: "Noto Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 71%;
      }

      .button-group {
        display: flex;
        gap: 16px;
        margin-top: 24px;

        .invite-button {
          display: flex;
          padding: 12px 15px;
          justify-content: center;
          align-items: center;
          gap: 6px;
          align-self: stretch;
          color: #0A8394;
          border: 1px solid;
          border-radius: 4px;
          flex: 1 0 0;
          background: #ffffff;

          &:hover {
            background-color: #ebf5ff;
          }
        }

        .add-pan-button {
          display: flex;
          height: 40px;
          padding: 16px 20px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          flex: 1 0 0;
          border-radius: 4px;
          cursor: pointer;
          background-color: #0A8394;
          border: 1px solid;
          color: #e6f7f8;
          flex: 1 0 0;

          &:hover {
            background-color: #0f766e;
          }
        }
      }

      .first-row-info {
        color: var(--Neutral-700, #72747D);
        font-family: "Noto Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 13px;
      }
    }

    // .entities-section {
    //   width: 50%;

    //   .underline-1 {
    //     width: 99%;
    //     height: 2%;
    //     background: #EDEFF5;
    //     margin-bottom: 30px;
    //   }

    //   .entities-header {
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: flex-start;
    //     margin-bottom: 16px;
    //     width: 100%;

    //     h3 {
    //       font-family: "Noto Sans";
    //       font-size: 20px;
    //       font-style: normal;
    //       font-weight: 400;
    //       line-height: normal;
    //     }

    //     .view-detail {
    //       color: var(--Primaty2-Blue-700, #0A8394);
    //       font-family: "Noto Sans";
    //       font-size: 14px;
    //       font-style: normal;
    //       font-weight: 400;
    //       line-height: normal;
    //       text-decoration-line: underline;
    //       text-underline-position: from-font;
    //       border: none;
    //       background-color: #ffffff;
    //       cursor: pointer;
    //       width: 25%;

    //       &:hover {
    //         text-decoration: underline;
    //       }
    //     }
    //   }

    //   .entities-list {
    //     display: flex;
    //     flex-direction: column;
    //     gap: 16px;

    //     .entity-card {
    //       padding: 16px;
    //       border-radius: 12px;
    //       background: var(--Neutral-50, #FFF);
    //       box-shadow: 0px 4px 15px 0px #E8EAF1;

    //       .entity-header {
    //         display: flex;
    //         align-items: center;
    //         gap: 8px;
    //         margin-bottom: 12px;

    //         .pan-label {
    //           color: #4b5563;
    //         }


    //         .pan-value {
    //           color: var(--Primaty2-Blue-700, #0A8394);
    //           font-family: "Noto Sans";
    //           font-size: 14px;
    //           font-style: normal;
    //           font-weight: 600;
    //           line-height: normal;
    //         }

    //         .status-active {
    //           margin-left: auto;
    //           color: #059669;
    //           font-size: 12px;
    //           border-radius: 2px;
    //           border: 1px solid var(--Neutral-200, #EDEFF5);
    //           background: var(--Neutral-50, #FFF);
    //           padding: 2px 8px 2px 6px;
    //         }
    //       }


    //       .entity-header img {
    //         width: 25px;
    //       }

    //       .entity-details {
    //         display: flex;
    //         flex-direction: column;

    //         .detail-group {
    //           display: flex;
    //           justify-content: space-between;
    //           margin-top: 5px;

    //           .label {
    //             color: var(--Neutral-600, #A3A6B7);
    //             /* Base Text/Regular S */
    //             font-family: "Noto Sans";
    //             font-size: 12px;
    //             font-style: normal;
    //             font-weight: 400;
    //             line-height: normal;
    //           }

    //           .value {
    //             color: #0A8394;
    //             text-align: right;
    //             font-family: "Noto Sans";
    //             font-size: 12px;
    //             font-style: normal;
    //             font-weight: 500;
    //             line-height: normal;
    //           }
    //         }

    //         .status-badge {
    //           display: inline-block;
    //           padding: 4px 8px;
    //           border-radius: 4px;
    //           font-size: 12px;

    //           &.completed {
    //             background-color: #ecfdf5;
    //             color: #059669;
    //           }

    //           &.pending {
    //             background-color: #ebf5ff;
    //             color: #2563eb;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }


    
      .einvoicecard-card-container {
        background: #fff;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        width: 50%;

        // SCSS for vendor details
.vendor-details {

  .vendor-header-details{
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 18px;
    }
    
    .highlight {
      color: #0A8394;
    }
    
    .gstin-highlight {
      color: #0A8394;
    }
  }
  

  
  .vendor-table {
    border: 1px solid #eee;
    margin-top: 10px;
    border-radius: 10px;
    width: 100%;
    background: #fff;
  }
  
  .vendor-table .row {
    display: flex;
    align-items: center;
    padding: 6px 6px;
    border-bottom: 1px solid #eee;
  }
  
  .vendor-table .row:last-child {
    border-bottom: none;
  }
  
  .vendor-table .row span:first-child {
    flex: 0.5; /* Adjusts field label width */
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: #444;
  }
  
  .vendor-table .row span:last-child {
    flex: 0.5; /* Adjusts value width */
    text-align: left;
    font-size: 14px;
    color: #222;
    display: flex;
    align-items: center;
    gap: 8px; /* Creates spacing between value & badges */
  }
  
  /* Badge styles */
  .tag {
    background: #e3f8ff;
    color: #0891b2;
    font-size: 12px;
    padding: 3px 8px;
    border-radius: 12px;
    border: 1px solid #b2ebf2;
  }
  
  .status {
    font-size: 12px;
    padding: 3px 10px;
    border-radius: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .status.eligible {
    background-color: transparent;

  }
  
  .status.received {
    background: #ecfdf5;
    color: #047857;
    border: 1px solid #a7f3d0;
  }
  
  .status {
    padding: 4px 8px;
    border-radius: 5px;
    font-weight: bold;
  }
  
  .eligible {
    background: #e3fcef;
    color: #28a745;
  }
  
  .received {
    background: #d4edda;
    color: #155724;
  }
  
  .vendor-actions {
    margin-top: 15px;
    display: flex;
    gap: 10px;

    .einvoicecard-reset {
      background-color: transparent;
      border: 1px solid #0A8394;
      display: flex;
      padding: 5px 40px;
      justify-content: center;
      border-radius: 4px;
      gap: 10px;
      color: #0A8394;
      align-items: center;
    }
    
    button {
      padding: 10px 15px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
    
    .download-btn {
      background: #007bff;
      color: #fff;
    }
    
    .view-btn {
      background: #004085;
      color: #fff;
    }
  }
}



        .einvoice-toggle-switch {
          display: flex;
          position: relative;
          border-radius: 8px;
          background-color: #f3f4f6;
          padding: 4px;
          width: 150px;
          height: 26px;
          align-items: center;
          justify-content: space-between;
          position: relative;
          margin-bottom: 20px;
        }
        
        .einvoice-toggle-option {
          flex: 1;
          text-align: center;
          font-weight: 600;
          font-size: 14px;
          cursor: pointer;
          position: relative;
          z-index: 2;
          color: #9ca3af; // Default inactive color
          &.active {
            color: var(--Primaty2-Blue-700, #0A8394);
            // border: 1px solid rgba(0, 132, 139, 0.20);
            border-radius: 4px;
          }
        }

        
        
        .einvoice-toggle-indicator {
          position: absolute;
          width: 50%;
          height: 80%;
          background-color: #e0f2fe;
          border-radius: 6px;
          transition: all 0.3s ease-in-out;
          left: 4px;
          z-index: 1;
          font-family: "Noto Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        
        .einvoice-toggle-indicator.right {
          left: 50%;
        }
        
      
        
        .einvoicecard-eligibility {
          background: none;
          border: 1px solid var(--Primaty2-Blue-700, #0A8394);
          color: #0A8394;
          padding: 5px 10px;
          border-radius: 20px;
          cursor: pointer;
          margin-bottom: 15px;
        }
    
        .einvoicecard-title {
          color: var(--Neutral-950, #212121);
          /* Regular Head/Regular H1 */
          font-family: "Noto Sans";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 20px;
        }
    
        .einvoicecard-form-group {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
    
          .einvoicecard-input-container {
            display: flex;
            flex-direction: column;
            width: 48%;
    
            label {
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              margin-bottom: 5px;
              color: var(--Neutral-950, #212121);
            }
            span {
              color: #E53232;
            }
    
            input {
              padding: 8px;
              border: 1px solid #ccc;
              border-radius: 5px;
            }

            .invoice-select {
              background-color: white;
              padding: 8px;
              border: 1px solid #ccc;
              border-radius: 5px;
              font-size: 14px;
              text-align: left;
              color: #000;
              cursor: pointer;
            }
          
            .invoice-select:focus {
              outline: none;
              border-color: #007bff;
            }
          }
        }
    
        .einvoicecard-button-group {
          display: flex;
          margin-top: 20px;
          gap: 20px;
    
          button {
            
            cursor: pointer;
          }
    
          .einvoicecard-reset {
            background-color: transparent;
            border: 1px solid #0A8394;
            display: flex;
            padding: 5px 40px;
            justify-content: center;
            border-radius: 4px;
            gap: 10px;
            color: #0A8394;
            align-items: center;
          }
          .einvoicecard-cancel {
            background-color: transparent;
            border: 1px solid #0A8394;
            display: flex;
            padding: 5px 40px;
            justify-content: center;
            border-radius: 4px;
            gap: 10px;
            color: #0A8394;
            align-items: center;
          }
    
          .einvoicecard-validate {
            border-radius: 4px;
            padding: 5px 40px;
            background: var(--Primaty2-Blue-700, #0A8394);
            color: #fff;
            border: none;
          }
        }
      }

    
  }

  .container-second-row {
    padding: 20px;
    background: #f8f9fa;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    display: flex;
    width: 96%;
    column-gap: 27px;
    box-sizing: border-box;
    margin: 0 42px;

    .date-range-section {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 20px;
      color: #333;
    }

    .dashboard-section {
      background: white;
      border-radius: 12px;
      padding: 24px;
      margin-bottom: 24px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      width: 35%;
      box-sizing: border-box;

      .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        .header-tabs {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .tab-button {
            padding: 6px 12px;
            border: none;
            background: none;
            border-radius: 16px;
            font-size: 14px;
            cursor: pointer;

            &.active {
              color: var(--Primaty2-Blue-700, #0A8394);
              border: 1px solid var(--Primaty2-Blue-700, #0A8394)
            }
          }
        }
      }

      .section-subheading {
        display: flex;
        justify-content: space-between;
        font-family: "Noto Sans";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 9px;

        .view-detail {
          color: #00a3b1;
          text-decoration: none;
          font-size: 14px;

        }
      }

      .Amount-card{
        border-radius: 8px;
        border: 1px solid rgba(255, 43, 0, 0.60);
        background: #FED9D9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 40px 20px;


        .Amount-card-left{
          display: flex;
          align-items: center;
        }
        .Amount-card-right{
          display: flex;
        }
      }

      .pan-amount-cards{
        display: flex;
        width: 100%;
        margin-top: 20px;
        gap: 10px;

        .pan-amount-card{
          padding: 16px;
          border-radius: 8px;
          border: 1px solid var(--Neutral-300, #E8EAF1);
          background: var(--Neutral-50, #FFF);
          box-shadow: 0px 4px 15px 0px #E8EAF1;
          width: 45%;

          .entity-header {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 12px;

            .pan-label {
              color: #4b5563;
            }


            .pan-value {
              color: var(--Primaty2-Blue-700, #0A8394);
              font-family: "Noto Sans";
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }

            .status-active {
              margin-left: auto;
              color: #059669;
              font-size: 12px;
              border-radius: 2px;
              border: 1px solid var(--Neutral-200, #EDEFF5);
              background: var(--Neutral-50, #FFF);
              padding: 2px 8px 2px 6px;
            }
          }
          .underline-1 {
            width: 99%;
            height: 2%;
            background: #EDEFF5;
            margin-bottom: 30px;
          }

          .pan-amount-div{
            padding-bottom: 30px;

            .pan-amount-text{
              color: #FF2B00;
              font-family: "Noto Sans";
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            .pan-span{
              color: var(--Neutral-600, #A3A6B7);
              font-family: "Noto Sans";
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
      

        }
      }


      .stats-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        gap: 24px;
        height: 85%;

        .stat-card {
          background: white;
          border-radius: 8px;
          padding: 16px;
          border-radius: 8px;
          border: 1px solid var(--Neutral-300, #E8EAF1);
          background: var(--Neutral-50, #FFF);
          box-shadow: 0px 4px 15px 0px #E8EAF1;
          width: 80%;

          .stat-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;



            .stat-icon {
              width: 32px;
              height: 32px;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: white;
              font-weight: 500;

              &.total {
                background: #03A5B7;
              }

              &.compliant {
                background: #03A5B7;
              }

              &.non-compliant {
                background: #03A5B7;
              }

              &.missing {
                background: #03A5B7;
              }
            }
          }


          .more-options {
            border: none;
            background: none;
            color: #64748b;
            cursor: pointer;
            font-size: 20px;
            padding: 4px;
          }

          .underline {
            width: 99%;
            height: 2%;
            background: #EDEFF5;
            margin-bottom: 30px;
          }

          .stat-info {
            display: flex;
            gap: 4px;

            // .stat-title {
            //   color: #64748b;
            //   font-size: 14px;
            // }

            .stat-value {

              color: var(--Neutral-800, #3F4047);
              font-family: "Noto Sans";
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;

            }

            .stat-subtitle {
              color: var(--Neutral-600, #A3A6B7);
              font-size: 12px;
              display: flex;
              align-items: center;
            }
          }
        }
      }

      .section-content {
        display: flex;
        gap: 10px;

        .eligibility-chart {
          border-radius: 8px;
          border: 1px solid var(--Neutral-300, #E8EAF1);
          background: var(--Neutral-50, #FFF);
          box-shadow: 0px 4px 15px 0px #E8EAF1;
          width: 70%;
          height: 330px;
          padding-bottom: 30px;

          .chart-heading{
            padding-top: 40px;
            padding-left: 20px;
            font-size: 20px;
          }

         

          .chart-container {
            height: 300px;
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          h3 {
            font-size: 16px;
            margin-bottom: 20px;
            font-weight: 500;
          }

          .custom-legend {
            margin-top: 10px;
        
            ul {
              list-style: none;
              padding: 0;
              margin: 0;
              display: flex;
              flex-direction: column;
              justify-content: center;
              gap: 20px;
        
              li {
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #4a5568;
        
                .legend-marker {
                  width: 12px;
                  height: 12px;
                  border-radius: 50%;
                  margin-right: 8px;
                  display: inline-block;
                }
              }
            }
          }

          
        }

        .msme-stats {
          display: flex;
          flex-direction: column;
          gap: 24px;
          width: 30%;

          .stat-card {
            background: white;
            border-radius: 8px;
            padding: 16px;
            border-radius: 8px;
            border: 1px solid var(--Neutral-300, #E8EAF1);
            background: var(--Neutral-50, #FFF);
            box-shadow: 0px 4px 15px 0px #E8EAF1;
            width: 90%;
            height: 120px;
  
            .stat-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;


              .stat-title {
                font-size: 12px;
                color: var(--Neutral-700, #72747D);
              }
  
  
  
              .stat-icon {
                width: 32px;
                height: 32px;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                font-weight: 500;
  
                &.total {
                  background: #03A5B7;
                }
  
                &.compliant {
                  background: #03A5B7;
                }
  
                &.non-compliant {
                  background: #03A5B7;
                }
  
                &.missing {
                  background: #03A5B7;
                }
              }
            }
  
  
            .more-options {
              border: none;
              background: none;
              color: #64748b;
              cursor: pointer;
              font-size: 20px;
              padding: 4px;
            }
  
            .underline {
              width: 99%;
              height: 2%;
              background: #EDEFF5;
              margin-bottom: 30px;
            }
  
            .stat-info {
              display: flex;
              gap: 4px;
  
              
  
              .stat-value {
  
                color: var(--Neutral-800, #3F4047);
                font-family: "Noto Sans";
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
  
              }
  
              .stat-subtitle {
                color: var(--Neutral-600, #A3A6B7);
                font-size: 12px;
                display: flex;
                align-items: center;
              }
            }
          }


        }
      }
    }
    .vendor-compliance-container {
      background-color: #f8f9fa;
      width: 35%;

    
      .card {
        background: #ffffff;
        padding: 150px 20px;
        border-radius: 12px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        width: 90%;

    
        .vendor-score {
          background: transparent;
          color: #007b8f;
          border: 1px solid #007b8f;
          border-radius: 12px;
          padding: 6px 12px;
          font-size: 14px;
          cursor: pointer;
        }
    
        h2 {
          font-size: 20px;
          margin: 16px 0;
          color: #333;
          font-weight: 400;
        }
    
        label {
          display: block;
          text-align: left;
          font-size: 14px;
          color: #555;
          margin-bottom: 4px;
    
          .required {
            color: red;
          }
        }
    
        input {
          width: 95%;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 5px;
          font-size: 16px;
          margin-bottom: 16px;
        }
    
        .check-btn {
          background: #007b8f;
          color: #fff;
          width: 100%;
          padding: 20px 12px;
          border-radius: 5px;
          font-size: 16px;
          cursor: pointer;
          display: flex;
          gap: 30px;
          border: none;
    
          &:hover {
            background: #005f6b;
          }
        }
      }
    }
    

    
  }
}