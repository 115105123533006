.JoinWorkspace {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  .wrapperContainer {
    padding: 20px 0;
    box-sizing: border-box;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    .mmtLogo {
      width: 120px;
    }
  }
}
