.CustomReconTabs {
  display: flex;
  align-items: center;
  .ReconTab {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 2px 8px;
    border-radius: 4px;
    transition: all 0.4s;
    background-color: white;
    margin-right: 12px;

    &:hover {
      background: #2575dc2e;
      // border: 1px solid #e1e4e9b0;
      svg {
        fill: var(--base-color-blue);
      }
      p {
        color: var(--base-color-blue) !important;
      }
    }

    &.active {
      background: #2575dc2e;
      // border: 1px solid #e1e4e9b0;
      svg {
        fill: var(--base-color-blue);
      }
      p {
        color: var(--base-color-blue) !important;
      }
    }
  }
}
