.AppTour {
  height: 100vh;
  width: 100%;
  // background-color: #f6f6f6;
  background-color: #f8f9fb !important;

  display: flex;
  // align-items: center;
  // flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .StepContainer {
    width: 22%;
    box-sizing: border-box;
    padding: 24px 20px;
    .AppLogo {
      margin-bottom: 32px;
      img {
        height: 32px;
        width: 120px;
      }
    }
  }
  .ContentContainer {
    height: 100%;
    width: 78%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
    // padding: 20px 28px;
    box-sizing: border-box;
    // box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    .StepContainer {
      width: 100%;
    }

    .DetailContainer {
      height: 100%;
    }
  }

  .backIllustration {
    position: absolute;
    bottom: -46px;
    right: -57px;
    transform: rotate(331deg);
    opacity: 0.2;
  }

  .Greeting {
    height: 100%;
    // padding: 100px 40px;
    .BusinessForm {
      display: flex;
      flex: 1;
      flex-direction: column;
      .FormContainer {
        width: 100%;
        display: flex;
        align-items: flex-start;
        .UploadSection {
          width: 50%;
          // height: 60%;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: center;
          padding-left: 24px;
          border: 1px solid #dfe0e7;
          padding: 32px;
          box-sizing: border-box;
          border-radius: 15px;
        }
        .CustomDivider {
          height: 90%;
          width: 1px;
          background-color: rgb(128 128 128 / 15%);
        }
        .SingleCredentials {
          width: 50%;
          // height: 60%;
          padding-right: 24px;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: flex-start;
        }
      }
    }
    .TableContainer {
      flex: 1;
      .ant-float-btn-icon {
        width: 100% !important;

        div {
          width: 200px;
          display: flex;
          justify-content: center;
        }
      }
    }

    .SolutionView {
      display: flex;
      align-items: center;
      margin-top: 66px;

      .SolutionCard {
        width: 320px;
        height: 240px;
        border-radius: 12px;
        background-color: var(--base-color-gray);
        padding: 20px;
        padding-top: 42px;
        box-sizing: border-box;
        position: relative;
        margin-right: 32px;
        box-sizing: border-box;
        cursor: pointer;
        transition: transform 0.4s;
        border: 2px solid var(--base-color-gray);

        .Title {
          background: white;
          padding: 2px 8px;
          border-radius: 15px;
          border: 1px solid gray;
        }

        .SolCheck {
          position: absolute;
          width: 38px;
          height: 38px;
          border-radius: 50%;
          background-color: var(--base-color-green);
          border: 2px solid white;
          top: -16px;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &:hover {
          border: 2px solid var(--base-color-primary);
          transform: scale(1.1);
        }
      }
    }
  }
  //styles for connect for
  .ConnectFormContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .LeftContainer {
      width: 50%;
      padding: 12px;
      box-sizing: border-box;

      .ContactItem {
        margin: 24px 0;
        display: flex;
        align-items: center;
      }
    }

    .RightContainer {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .FormContainer {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 24px;
        width: 72%;
        border-radius: 11px;
        form {
          .FormRow {
            margin-bottom: 24px;
          }
        }
      }
    }
  }

  .FinishScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 72px;
    }
  }

  .ant-float-btn-icon {
    width: 100% !important;
    div {
      width: 200px;
      display: flex;
      justify-content: center;
    }
  }
  .ant-steps-item-content {
    display: flex !important;
    align-items: center !important;
  }
  .ant-steps-item-tail {
    padding: 48px 0 6px !important;
    height: 140% !important;
  }
}

//ant modifiers

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: var(--base-color-primary) !important;
  border-color: var(--base-color-primary) !important;

  svg {
    color: white;
  }
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: var(--base-color-primary) !important;
}

.ant-steps-item-tail::after {
  width: 2px !important;
}
.ant-steps-item-icon {
  width: 24px !important;
  height: 24px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: var(--base-color-primary) !important;
  border: 2px solid var(--base-color-primary) !important;

  box-shadow: 0 0 0 4px #0a849465 !important;
}
.ant-steps-icon {
  display: none !important;
}

.ant-steps-item-process {
  padding-left: 10px !important;
  background-color: rgba(0, 132, 139, 0.1);
  border-radius: 12px;

  // p {
  //   color: var(--base-color-primary) !important;
  // }
}
.ant-steps-item-finish {
  padding-left: 10px !important;
}

.ant-steps-item-process {
  padding-left: 10px !important;
  background-color: rgba(0, 132, 139, 0.1);
  border-radius: 12px;
  height: 70px;
  display: flex !important;
  align-items: center;
}
.ant-steps-item-tail {
  margin-left: 10px !important;
}
.ant-steps-item-wait {
  padding-left: 10px !important;
  height: 70px;
  display: flex !important;
  align-items: center;
  .ant-steps-item-icon {
    background-color: white !important;
    border: 1px solid #dfe0e7 !important;
  }
}

.ant-steps-finish-icon {
  font-size: 11px !important;
}

.ant-steps-item-title {
  font-size: 12px !important;
}

.ant-steps-item-container {
  display: flex !important;
  align-items: center !important;
}

.ant-steps-item-finish {
  .ant-steps-item-container {
    .ant-steps-item-title {
      color: var(--base-color-primary) !important;
      font-weight: 500;
    }
  }
}

.ant-steps-item-container {
  padding-left: 4px;
}
.ant-steps-item {
  padding: 5px 0;
}
