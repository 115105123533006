.ReportSelection {
  display: flex;
  flex-direction: column;
  height: 100%;
  .HeaderContainer {
    height: 40px;
  }
  .MainContainer {
    flex: 1;
    .ViewContainer {
      height: 100%;
      width: 100%;
    }
  }
}
