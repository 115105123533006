.InviteMember {
  margin-top: 24px;
  .MemberList {
    margin-top: 32px;
    .MemberItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      .ItemLeft {
        display: flex;
        align-items: center;
      }
      .ItemRight {
        display: flex;
        align-items: center;
      }
    }
  }
  .BottomAction {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
