.BookingData {
  // padding: 24px;
  flex: 1;
  .ScreenContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .TableContainer {
      flex: 1;
      .ag-root-wrapper {
        border: none !important;
      }
      .WrapperContainer {
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 20px 0;
        box-sizing: border-box;
        padding: 24px;
        box-sizing: border-box;
        justify-content: space-between;
        flex: 1;

        .RowContainer {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;

          .Column {
            // box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
            //   rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
            padding: 16px;
            box-sizing: border-box;
            border-radius: 8px;
            background-color: white;
            height: 96px;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          }
        }
      }
    }
    .TabContainer {
      height: 100%;
      margin-top: 12px;
    }
  }
  iframe {
    border: none !important;
  }
}
