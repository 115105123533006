.container3 {
    padding: 20px;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    width: 90%;
    min-height: 400px;
  }
  
  .table-head {
    margin-bottom: 10px;
    text-align: left;
  }
  
  .header-line {
    border-top: 2px solid #e1e1e1;
    margin: 0 0 20px 0;
  }
 