/* src/WorkspacePage.css */
.workspace-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white; /* White background */
    font-family: 'Arial', sans-serif;
    padding: 20px;
    justify-content: space-between;
  }
  
  .header-gst-2{
    font-size: 1.5rem;
    margin-bottom: 50px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #0A8394;
  }
  
  .workspace-options {
    display: flex;
    margin-bottom: 30px;
    width: 100%;
  }
  
  .workspace-left {
    margin-right: 50px;
  }
  
  .checkbox-label {
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .checkbox-input {
    margin-right: 10px;
    transform: scale(1.5); /* Larger checkbox */
  }
  
  .workspace-right {
    flex-grow: 1;
    margin-left: 200px;
  }
  
  .workspace-list {
    list-style: none;
    padding: 0;
  }
  
  .workspace-item {
    margin-bottom: 15px;
    font-size: 16px;
  }
  
  .radio-input {
    margin-right: 10px;
    transform: scale(1.2); /* Larger radio button */
  }
  
  .save-button-container {
    margin-top: 20px;
  }
  .search-input-gst {
    width: 40%;
    padding: 10px 15px;
    border: 1px solid #ccc; /* Gray border */
    border-radius: 4px;
    margin-bottom: 20px;
    font-size: 1rem;
    outline: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .search-input::placeholder {
    color: #999;
  }
  .save-button {
    padding: 12px 30px;
    background-color: #28a745; /* Green color */
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
