.PageNotFound{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    color:#7d8396;

    .ErrorMessage{
        font-size: 12rem;
        font-weight: 700;
        color:#7d8396
    }
    a{
        color: #3498db;
        cursor: pointer;
        margin-left: 4px;
        font-weight: 600;
    }
}