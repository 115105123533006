.GSTAddReview {
  // padding: 55px;
  padding: 60px 88px;

  box-sizing: border-box;
  height: 100vh;
  background-color: #f8f9fb;
  .TopLogo {
    img {
      width: 120px;
     margin-left: 20px;
    }
    .MiniDivder {
      width: 72px;
      border-bottom: 2px solid var(--base-color-primary);
      margin-top: 6px;
      margin-left: 25px;
    }
  }
  .MainContainer {
    width: 100%;
    height: 100%;
    display: flex;
    // justify-content: space-between;
    gap: 200px;

    .LeftContainer {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      .CompanyContainer {
        display: flex;
        align-items: center;
        img {
          width: 44px;
          object-fit: contain;
          gap: 24px;
        }
      }
      
      .MapContainer {
        width: 100%;
        height: 80%;
        position: relative;
        svg {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-self: center;
          left: 15% !important;
          top: 8% !important;
        }
      }
    }
    .RightContainer {
      width: 50%;
      .AirlineStart {
        padding: 20px 24px;

        box-sizing: border-box;
        width: 100%;
        min-height: 50%;
        background-color: white;
        border-radius: 12px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        .QuestionareContainer {
          display: flex;
          flex-direction: column;
          .QuestionBox {
            border-radius: 8px;
            width: 100%;
            border: 1px solid #dfe0e7;
            padding: 20px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;
            cursor: pointer;
            .LeftQuestion {
              display: flex;
              align-items: center;
              .IconBox {
                height: 44px;
                width: 44px;
                border-radius: 50%;
                background: #0a83941f;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 24px;
              }
            }
          }
          .--selected {
            border: 1px solid #00848b33;
            background: #e5fbfd80;
          }
        }
        .FormContainer {
          margin-top: 12px;
          .FormGroup {
            margin-bottom: 12px;
          }
        }

        .EnitiyInfoContainer {
          margin-top: 12px;
          .EntityNameContainer {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-bottom: 12px;
          }
        }
        .EntityBoxContainer {
          display: flex;
          flex-wrap: wrap;
          margin-top: 12px;

          .EntityBox {
            border: 1px solid #dfe0e7;
            padding: 12px;
            box-sizing: border-box;
            border-radius: 8px;
            margin-right: 8px;
            cursor: pointer;
            .EntityBoxFooter {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 18px;
            }
          }
        }
        .FormContainer {
          display: flex;
          flex-direction: column;
          margin-top: 24px;
          .fieldGroup {
            display: flex;
            flex-direction: column;
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}

.datamaps-legend {
  display: none;
}

#root-svg-group > g:last-child {
  display: none;
}

.datamaps-subunit:hover {
  fill: #00ff00; /* Your hover color */
}
