/* src/AddItemPage.css */
.add-item-page {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: white; /* White background */
    color: #333;
    font-family: 'Arial', sans-serif;
    justify-content: space-evenly;
    margin-left:'100px';
  }
  
  .header-gst {
    font-size: 1.5rem;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .form-container-gstin {
    display: flex;
    align-items: center;
    width: 400px;
  }
  
  .input-box-gstin {
    padding: 10px 15px;
    border: 1px solid #ccc; /* Gray border */
    border-radius: 4px;
    width: 300px;
    margin-right: 10px; /* Space between input and button */
    font-size: 1rem;
    outline: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .input-box::placeholder {
    color: #999;
  }
  
  .add-button {
    padding: 10px 20px;
    background-color: #007bff; /* Blue color */
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .add-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  .error-message {
    margin-top: 20px;
    color: #ff4d4d;
    font-size: 1rem;
  }

  .gstimg-addgstpage{
    height: 500px;
    width: 600px;
  }
  