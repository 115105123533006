.ColMapTablePreview {
  width: 100%;
  display: flex;
  height: 100%;
  box-shadow: 1px 1px 1px 1px gray;
  flex-direction: column;
  .Header {
    height: 40px;
  }
  .Content {
    display: flex;
    flex: 1;
    .LeftPanel {
      width: 280px;
      border-right: 1px solid rgba(215, 210, 210, 0.812);
      .PanelHeader {
        padding: 12px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
      }
      .PanelList {
        .PanelCard {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 12px 12px;
          box-sizing: border-box;
          transition: all 0.4;
          cursor: pointer;
          &:hover {
            background-color: rgb(204, 203, 203);
          }
          img {
            width: 20px;
            height: 20px;
            margin-right: 6px;
            object-fit: cover;
          }
        }
      }
    }

    .MainContent {
      height: 100%;
      width: 100%;
      background-color: white;
      display: flex;
      flex-direction: column;
      .TabContent {
        padding: 9px 20px;
        box-sizing: border-box;
      }
      .DBPanel {
        width: 260px;
        height: 100%;
        border-right: 1px solid rgba(215, 210, 210, 0.812);
        .PanelHeader {
          padding: 12px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
        }
        .PanelList {
          .PanelCard {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 12px 12px;
            box-sizing: border-box;
            transition: all 0.4;
            cursor: pointer;
            &:hover {
              background-color: rgb(204, 203, 203);
            }
            img {
              width: 20px;
              height: 20px;
              margin-right: 6px;
              object-fit: cover;
            }
          }
        }
      }
      .TableListContainer {
        height: 100%;
        width: 100%;
        flex: 1;
      }
    }
  }
  .ag-cell {
    padding: 0 !important;
  }
}
