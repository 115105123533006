.FinkAI {
  position: absolute;
  bottom: 38px;
  right: 38px;
  .InitButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    .FinkContainer {
      // width: 42px;
      // height: 42px;
      // border-radius: 50%;
      box-shadow: rgba(0, 0, 0, 0) 0px 22px 70px 4px;
      background-color: transparent;
    }
    .ant-avatar {
      transition: all 0.3s !important;
    }
    &:hover {
      .ant-avatar {
        transform: scale(1.1);
      }
    }
    .wave-emoji {
      display: inline-block;
      animation: wave-animation 2s infinite;
      transform-origin: 70% 70%; /* This makes the pivot point around the wrist */
    }

    @keyframes wave-animation {
      0% {
        transform: rotate(0deg);
      }
      10% {
        transform: rotate(14deg);
      }
      20% {
        transform: rotate(-8deg);
      }
      30% {
        transform: rotate(14deg);
      }
      40% {
        transform: rotate(-4deg);
      }
      50% {
        transform: rotate(10deg);
      }
      60% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }

  .ChatContainer {
    position: absolute;
    right: 30px;
    bottom: 0px;
    max-width: 400px;
    background: #fff;
    height: 520px;
    width: 380px;
    border-radius: 8px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    &.active {
      opacity: 1;
      bottom: 40px;
      pointer-events: auto;
    }
    .ChatHeader {
      padding: 26px 14px;
      border-bottom: 1px solid var(--base-color-blue-hover);
      height: 40px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      justify-content: space-between;
      .HeaderLeft {
        display: flex;
        align-items: center;
      }
      .HeaderRight {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    .Chatbody {
      flex: 1;
      padding: 12px 18px;
      overflow-y: auto;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .ChatLeft {
        display: flex;
        align-items: flex-start;
        margin-bottom: 16px;

        .UserAvatar {
          width: 28px;
          height: 28px;
          margin-right: 12px;
        }
        .ChatContent {
          display: flex;
          flex-direction: column;
          .TextContainer {
            padding: 8px;
            box-sizing: border-box;
            border-radius: 8px;
          }
          .ActionContainer {
            margin-top: 14px;
            display: flex;
            flex-wrap: wrap;
            .ActionItem {
              border-radius: 15px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 2px 6px;
              width: auto;
              margin-bottom: 8px;
              margin-right: 8px;
            }
          }
        }
      }
      .ChatRight {
        margin-bottom: 16px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        .UserAvatar {
          width: 28px;
          height: 28px;
          margin-left: 12px;
        }
        .ChatContent {
          display: flex;
          flex-direction: column;
          .TextContainer {
            padding: 8px;
            box-sizing: border-box;
            border-radius: 8px;
          }
          .ActionContainer {
            margin-top: 14px;
            display: flex;
            flex-wrap: wrap;
            .ActionItem {
              border-radius: 15px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 2px 6px;
              width: auto;
              margin-bottom: 8px;
              margin-right: 8px;
            }
          }
        }
      }
    }
    .ChatInputContainer {
      height: 34px;
      padding: 14px;

      .ripple-circle {
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #d63031;
        overflow: hidden;
        animation: ripple-animate 1.5s infinite ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .ripple-circle::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        animation: ripple 1.5s infinite ease-in-out;
      }

      @keyframes ripple {
        0% {
          transform: translate(-50%, -50%) scale(0);
          opacity: 1;
        }
        100% {
          transform: translate(-50%, -50%) scale(1.5);
          opacity: 0;
        }
      }

      @keyframes ripple-animate {
        0%,
        100% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.1);
        }
      }
    }
  }
}
