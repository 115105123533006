.JoinWorkspace {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  .wrapperContainer {
    padding: 20px 0;
    box-sizing: border-box;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    .Container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      .FormContainer {
        //   height: 320px;
        width: 540px;
        padding: 28px 24px;
        box-sizing: border-box;
        background-color: #0a839412;
        border-radius: 20px;
        .OtpContainer {
          margin-top: 28px;
        }
        .ActionContainer {
          margin-top: 28px;
          width: 100%;
        }
      }
    }
  }
}
