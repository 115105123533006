// @import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900");

body {
  font-family: "Noto Sans", sans-serif !important;
  margin: 0 !important;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-block-start: 0;
  margin-block-end: 0;
}

// @media (min-width: 1200px) {
//   .maxWidthLG {
//     max-width: 100%;
//     margin-left: auto;
//     margin-right: auto;
//   }
// }

// @media (min-width: 600px) {
//   .maxWidthLG {
//     padding-left: 24px;
//     padding-right: 24px;
//   }
// }

//ant-design modifiers
.ant-btn {
  box-shadow: none !important;
}

.ant-form-item {
  margin-bottom: 0 !important;
}
// .ant-btn-primary:disabled {
//   background-color: #d9d9d9 !important;
// }

.ant-tabs-tab-btn {
  display: flex;
  align-items: center;
}

.ant-tabs-tab {
  padding: 4px 0 !important;
}

.__floater {
  .__floater__open {
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
      rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px !important;
  }
}

.ant-dropdown-menu {
  margin-top: 10px; /* Adjust the margin value as needed */
}
// .ant-tabs-tab-btn {
//   .ant-tabs-tab-icon {
//     svg {
//       color: #8a8a90;
//     }
//   }
//   span {
//     color: #8a8a90;
//   }
// }

.ant-tabs-tab {
  .ant-tabs-tab-icon {
    svg {
      color: #8a8a90;
    }
  }
  span {
    color: #8a8a90;
  }
}

/* Custom styles for active tab */
.ant-tabs-tab-active {
  border: none !important;

  .ant-tabs-tab-icon {
    svg {
      color: var(--base-color-blue);
    }
  }
  span {
    color: var(--base-color-blue);
  }
}

.ant-tabs {
  font-family: "Noto Sans", sans-serif !important;
}

.ant-tabs-tab-remove {
  margin-right: 4px !important;
  border: none !important;
}

.ant-typography,
.ant-form-item-label,
.ant-input,
.ant-select-selector,
.ant-upload-wrapper {
  font-family: "Noto Sans", sans-serif !important;
}

//DOM Highlighter CSS
.domHighlighterCircle {
  width: 8px;
  height: 8px;
  background-color: #ff7675; /* Change the color as desired */
  border-radius: 50%;
  position: relative;
}

.domHighlighterCircle-ripple {
  position: absolute;
  width: 0;
  height: 0;
  border-radius: 50%;
  border: 1px solid #ff7675; /* Adjust color and opacity as needed */
  animation: domHighlighter-ripple-animation 2.5s infinite;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.domHighlighterCircle-ripple:nth-child(2) {
  animation-delay: 0.5s; /* Adjust delay for each ripple */
}

.domHighlighterCircle-ripple:nth-child(3) {
  animation-delay: 1s; /* Adjust delay for each ripple */
}

@keyframes domHighlighter-ripple-animation {
  0% {
    width: 12px;
    height: 12px;
    opacity: 1;
  }
  100% {
    width: 20px;
    height: 20px;
    opacity: 0;
  }
}
