.PageHeader {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;
  border-bottom: 1px solid #e9ecf0;
  background-color: white !important;
  width: 100%;
  box-sizing: border-box;
  .HeaderLeft {
    display: flex;
    align-items: center;
    // padding-left: 12px;

    .ItemContainer {
      // margin-right: 24px;
    }
    .ItemContainer {
      overflow: hidden; /* Hide both horizontal and vertical scrollbars */
    }

    .ItemContainer::-webkit-scrollbar {
      display: none; /* Hide scrollbar for webkit browsers */
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .ItemContainer {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
    .externalAction {
      padding: 0 12px;
    }
  }
  .HeaderRight {
    display: flex;
    align-items: center;
    // min-width: 300px;
    justify-content: flex-end;

    .ItemContainer {
      // margin-right: 24px;
    }

    .externalAction {
      padding: 0 12px;
    }
  }
}

.ant-dropdown-menu-title-content {
  p {
    overflow: hidden !important;
    // width: 50%;
  }
}

.ant-dropdown-menu {
  max-height: 300px; /* Adjust the height as needed */
  overflow-y: auto; /* Add scrollbar if content overflows */
  overflow-x: hidden;
}
