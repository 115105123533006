.ReportCreate {
  display: flex;
  flex-direction: column;
  height: 100%;
  .Header {
    height: 40px;
  }
  .MainContent {
    flex: 1;
    height: 100%;
    display: flex;

    .DatabaseListContainer {
      height: 100%;
      width: 300px;
      border-right: 1px solid rgb(229, 227, 227);
      overflow-y: auto;
    }
    .TableViewContainer {
      display: flex;
      height: 100%;
      flex: 1;
    }
    .ThirdPartySourceContainer {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
