.OnboardingV2 {
  width: 100wh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f7f8fc;
  .HeaderContainer {
    width: 100%;
    height: 72px;
  }
  .ContentBody {
    flex: 1;
    display: flex;
  }

  th {
    font-weight: 400 !important;
  }
}
