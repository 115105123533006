.CreateEmail {
  display: flex;

  height: 100%;
  width: 100%;
  /* padding: 20px; */
}

.CreateEmail .FormContent {
  width: 100%;
}
.FormContent .FormRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.CreateEmail .FormContent .FormGroup {
  width: 48%;
}
