.OnboardingHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 100%;
  background-color: white;

  .OnboardingHeaderLeft {
    display: flex;
    align-items: center;
    img {
      height: 32px;
    }
  }
  .OnboardingHeaderRight {
    display: flex;
    align-items: center;
  }
}
