.gstr31-table-container {
  padding: 20px;
  width: 90%;
  min-height: 400px;
  flex-shrink: 0;
  font-family: 'Noto Sans', sans-serif;
}
.gstr31-heading{
  display: flex;
  justify-content: space-between;
}

h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.header-line {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
}

.negative-delay {
  color: red;
}
/* Add this to Tables.css */
.gstr31-table-container .ag-theme-alpine .ag-body-viewport::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit browsers */
}

.gstr31-table-container .ag-theme-alpine .ag-body-viewport {
  -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer and Edge */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}
