.OpenReportView {
  display: flex;

  height: 100%;
  .PrimaryNavContainer {
    width: 80px;
  }
  .MainSectionContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    .HeaderContainer {
      height: 40px;
    }
    .MainContent {
      flex: 1;
      .ViewContainer {
        height: 100%;
        width: 100%;
      }
    }

    .SheetsContainer {
      height: 44px;
      padding: 0 24px;
      border-top: 1px solid #e9ecf0;
      display: flex;
      align-items: center;
    }

    .hiddenTable {
      visibility: hidden;
      height: 0 !important;
      width: 0 !important;
      overflow: hidden;
    }
  }
}
