.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure modal is on top */
  }
  .modal-input-box{
    border: 10px;
    padding: 10px;
    border: 1px solid black;
  }
  
  .user-modal-content{
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    height: 700px;
  }
  
  .clickable-cell {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }
  

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    width: 600px;
    border-radius: 8px;
  }
  
  .modal-body {
    display: flex;
    justify-content: space-between;
  }
  
  .modal-left, .modal-right {
    width: 45%;
  }
  
  .cluster-list, .workspace-list {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
  }
  
  .cluster-list li, .workspace-list li {
    margin-bottom: 10px;
  }
  
  .progress-bar {
    background-color: #e0e0e0;
    border-radius: 5px;
    height: 10px;
    margin-top: 5px;
  }
  
  .progress {
    background-color: #76c7c0;
    height: 100%;
    border-radius: 5px;
  }
  .modal-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-bottom: 50px;
  }