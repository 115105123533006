.SettingHome {
  display: flex;
  flex-direction: column;
  .Header {
    height: 40px;
  }
  .MainContent {
    flex: 1;

    .SourceContainer {
      flex-wrap: wrap;
      display: flex;
      padding: 20px;
      box-sizing: border-box;
      .SourceCard {
        width: 260px;
        height: 180px;
        border-radius: 12px;
        background-color: var(--base-color-gray);
        padding: 16px;
        box-sizing: border-box;
        position: relative;
        margin-right: 24px;
        box-sizing: border-box;
        cursor: pointer;
        transition: transform 0.4s;
        border: 2px solid var(--base-color-gray);
        img {
          width: 38px;
        }

        &:hover {
          border: 2px solid var(--base-color-primary);
          transform: scale(1.1);
        }
      }
    }
  }
}
