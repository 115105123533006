.IntegrationNew {
  width: 100%;
  height: 100%;
  .ScreenContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .Header {
      height: 40px;
    }
    .MainContainer {
      flex: 1;
      display: flex;
      padding: 20px;
      box-sizing: border-box;
      align-items: flex-start;
      justify-content: space-between;
      .AirlineListContainer {
        width: 24%;

        .AirlineListContent {
          background: #f8f9fb;
          border: 1px solid #e8eaf1;
          padding: 18px;
          box-sizing: border-box;
          border-radius: 8px;
          .ItemList {
            display: flex;
            flex-direction: column;
            margin-top: 24px;
            height: 70vh;
            overflow-y: auto;
            .Item {
              display: flex;
              align-items: center;
              padding: 6px 12px;
              transition: all 0.3s;
              border-radius: 4px;
              cursor: pointer;
            }
            .Item:hover {
              background: #0a83941f;
            }
            .active {
              background: #0a83944d;
            }
          }
        }
      }

      .CredentialFormContainer {
        width: 49%;
        .AirlineInfoContainer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .HeaderContainerInfo {
            display: flex;
            align-items: center;
            .AirlineLogo {
              width: 44px;
              height: 44px;
              margin-right: 8px;
            }
          }
        }
        .FormContainer {
          display: flex;
          flex-direction: column;
          border: 1px solid rgb(211, 205, 205);
          padding: 20px;
          box-sizing: border-box;
          margin-top: 24px;
          border-radius: 8px;
          .FormGroup {
            margin-bottom: 24px;
          }
        }
      }
    }
  }
}
